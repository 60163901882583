import React from 'react'
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Button, Menu, MenuItem} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { work, about, home } from './Variables.js'

import './Header.css'
import '../App.css'

library.add(fas)


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      anchorLan: null
    }
  }

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleLanguageClick = (event) => {
    this.setState({ anchorLan: event.currentTarget })
  }

  closeMenu= (e) => {
    this.setState({ anchorEl: null, anchorLan: null})
  }

  changeLanguage = (event) => {
    this.props.onSetLanguage(event.target.id)
    this.closeMenu()
  }


  render() {
    return (
      <div className="wrapper headerContainer">
        <AppBar position="fixed" className='header'>
          <Toolbar className="header">
              <div className="leftSide">
                <Button 
                  onClick={this.handleMenuClick}
                  label="menu"
                  className="headerButton mobile-view"
                  aria-controls="simple-menu" 
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon icon='bars'/>
                </Button> 
                <Menu
                  id="menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  getContentAnchorEl={null}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  elevation={0}
                  onClose={this.closeMenu}
              
                >
                  <MenuItem component={Link} to="/" onClick={this.closeMenu}  className="headerButton"> 
                    {home[this.props.language]}
                  </MenuItem>

                  <MenuItem component={Link} to="/work" onClick={this.closeMenu}  className="headerButton"> 
                    {work[this.props.language]}
                  </MenuItem>

                  <MenuItem component={Link} to="/about" onClick={this.closeMenu}  className="headerButton">
                    {about[this.props.language]} 
                  </MenuItem>
                </Menu>
                
                <Button 
                  className="headerButton pc-view"
                  onClick={() => {this.props.history.push('/')}}
                > 
                  <div style={{display: 'inline', marginLeft: '10px', fontSize: 'initial'}}> {home[this.props.language]} </div>
                </Button>

                <Button 
                  className="headerButton pc-view"
                  onClick={() => {this.props.history.push('/work')}}
                > 
                  <div style={{display: 'inline', marginLeft: '10px', fontSize: 'initial'}}> {work[this.props.language]} </div>
                </Button>
                
                <Button 
                  className="headerButton pc-view"
                  onClick={() => {this.props.history.push('/about')}}
                > 
                  <div style={{display: 'inline', marginLeft: '10px', fontSize: 'initial'}}> {about[this.props.language]} </div>
                </Button>
        
              </div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleLanguageClick}
                color="inherit"
                style={{color: 'black', fontSize: 'smaller'}}
              >
                 {this.props.language}
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorLan}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(this.state.anchorLan)}
                onClose={this.closeMenu}
              >
                <MenuItem id='EN' onClick={this.changeLanguage}>EN</MenuItem>
                <MenuItem id='DE' onClick={this.changeLanguage}>DE</MenuItem>
              </Menu>

          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withRouter(Header);


/*
                  <MenuItem  component={Link} to="/teaching" onClick={this.closeMenu}  className="headerButton">
                    {teaching[this.props.language]} 
                  </MenuItem>

                  <Button 
                  className="headerButton pc-view"
                  onClick={() => {this.props.history.push('/teaching')}}
                > 
                  <div style={{display: 'inline', marginLeft: '10px', fontSize: 'initial'}}> {teaching[this.props.language]} </div>
                </Button>
*/

