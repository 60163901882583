import React from 'react'
import { withRouter } from "react-router";
import { Toolbar, Typography, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { impressum, datenschutz } from './Variables.js'

import './Footer.css'

library.add(fas)


class Footer extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <Toolbar className="footer">
        <Typography 
            label="contact"
            className= "footerContent"
          >
            <FontAwesomeIcon icon='copyright' style={{marginLeft: '16px', color:"white", textDecoration: 'none', fontSize: 'large'}}/> 
            <div style={{display: "inline", marginLeft: '2px',marginRight: '16px',color:"white", textDecoration: 'none', fontSize: 'large'}}> Julia Baldauf </div>
          </Typography> 
          <div>&nbsp;</div> 
          <Typography 
            label="contact"
            className= "footerContent"
          >
            <FontAwesomeIcon icon='envelope' style={{marginLeft: '16px', color:"white", textDecoration: 'none', fontSize: 'large'}}/> 
            <a href="mailto:mail@julia-baldauf.de" style={{marginLeft: '2px',marginRight: '16px',color:"white", textDecoration: 'none', fontSize: 'large'}}> mail@julia-baldauf.de </a>
          </Typography>  
          <Button 
            variant="h6"
          >
            <a href="https://www.linkedin.com/in/julia-baldauf-95716950/" target="_blank" rel="noreferrer" style={{color:"white", textDecoration: 'none', fontSize: 'large'}}> 
              <FontAwesomeIcon icon={faLinkedin} style={{marginLeft: '2px', color:"white", textDecoration: 'none', fontSize: 'large'}}/> 
            </a>
          </Button> 
          <Button 
            variant="h6"
          >
            <a href="https://github.com/chazarabriseis" target="_blank" rel="noreferrer" style={{color:"white", textDecoration: 'none', fontSize: 'large'}}> 
              <FontAwesomeIcon icon={faGithub} style={{marginLeft: '2px', color:"white", textDecoration: 'none', fontSize: 'large'}}/> 
            </a>
          </Button>  
          <Button 
            variant="h6"
            onClick={() => {this.props.history.push('/imprint')}}
          >
             <div style={{display: "inline", marginLeft: '5px',marginRight: '15px',color:"white", textDecoration: 'none', fontSize: 'large'}}> 
              {impressum[this.props.language]}
            </div>
          </Button>  
          <Button 
            variant="h6"
            onClick={() => {this.props.history.push('/dataprivacy')}}
          >
             <div style={{display: "inline", marginLeft: '5px',marginRight: '15px',color:"white", textDecoration: 'none', fontSize: 'large'}}> 
             {datenschutz[this.props.language]}
            </div>
          </Button>   
        </Toolbar>
      </div>
    )
  }
}

export default withRouter(Footer);
