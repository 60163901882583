import React from 'react'
import { withRouter } from "react-router";
import { Row, Panel} from 'react-bootstrap';
import { julia_en } from './data_julia.js'
import { julia_ge } from './data_julia_ge.js'
import { panel_work_heading, panel_edu_heading, panel_at, panel_in, panel_as, panel_furtheredu_heading, panel_pub_heading, panel_patent_heading, panel_awards_heading, panel_volunteer_heading} from './Variables.js'
import './About.css'
import '../App.css'

class CV extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            openWork: false,
            openEdu: false,
            openfurtherEdu: false,
            openPub: false,
            openPat: false,
            openAwards: false,
            openVol: false

        }
    }

  render () {

    var julia = julia_en
    if (this.props.language==='DE') {
        julia = julia_ge
    }
    
    return (
        <div className="row_cv">
            <Row >
                <Panel expanded={this.state.openWork} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openWork ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openWork: !this.state.openWork})}>
                        {panel_work_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                        {julia.work.map((workplacement) => (
                            <div key={workplacement.company} className='work_item'> 
                                <div className="work_overview">
                                    <div className="work_date">  {workplacement.startDate} - {workplacement.endDate} </div>
                                    <div className="work_position"> {panel_as[this.props.language]} {workplacement.position} </div>
                                    {workplacement.company.length > 0 &&(<div className="work_company"> {panel_at[this.props.language]} <a href={workplacement.website}>{workplacement.company} </a> </div>)} 
                                    <div className="work_location"> {panel_in[this.props.language]} {workplacement.location} </div>
                                </div>    

                                <div className="work_summary">
                                    {workplacement.summary}
                                </div>
                            </div>
                        ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel expanded={this.state.openEdu} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openEdu ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openEdu: !this.state.openEdu})}>
                        {panel_edu_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                            {julia.education.map((educationplacement) => (
                                <div key={educationplacement.institution} className='work_item'> 
                                    <div className="work_overview">
                                        <div className="work_date">  {educationplacement.startDate} - {educationplacement.endDate} </div>
                                        <div className="work_position"> {educationplacement.studyType} </div> 
                                        <div className="work_company"> {panel_at[this.props.language]} <a href={educationplacement.website}>{educationplacement.institution} </a> </div>
                                        <div className="work_location"> {panel_in[this.props.language]} {educationplacement.location} </div>
                                    </div>    
                                    { educationplacement.thesisTitle &&
                                    <div>
                                        <div className="work_overview">
                                            <div className="work_date">  {educationplacement.group} </div>
                                            <div className="work_position"> , {educationplacement.supervisor} </div> 
                                        </div> 

                                        <div className="work_overview">
                                            <div className="work_location"> Thesis Title: {educationplacement.thesisTitle} </div>
                                        </div>
                                    </div> 
                                    }

                                    <div className="work_summary">
                                        {educationplacement.description}
                                    </div>
                                </div>
                            ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel expanded={this.state.openfurtherEdu} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openfurtherEdu ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openfurtherEdu: !this.state.openfurtherEdu})}>
                        {panel_furtheredu_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                            {julia.courses.map((course) => (
                                <div key={course.name} className='work_item'> 
                                    <div className="work_overview">
                                        <div className="work_date">  {course.date} -</div>
                                        <div className="work_date course"> {course.name} </div> 
                                        <div className="work_date course"> {panel_at[this.props.language]} <a href={course.website}>{course.school} </a> </div>
                                    </div>    
                                </div>
                            ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel expanded={this.state.openPub} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openPub ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openPub: !this.state.openPub})}>
                        {panel_pub_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                            {julia.publications.map((publication) => (
                                <div key={publication.title} className='work_item'> 
                                    <div className="work_overview">
                                        <div className="work_date">  <a href={publication.website}>{publication.title} </a> </div>
                                        <div className="work_date course"> {publication.authors.map((author) => (<div className="work_date course"> {author},</div>))} </div> 
                                        <div className="work_date course"> {publication.year} </div> 
                                        <div className="work_date course"> {publication.journal} </div>
                                        <div className="work_date course"> {publication.volume} </div>
                                    </div>    
                                </div>
                            ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel expanded={this.state.openPat} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openPat ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openPat: !this.state.openPat})}>
                        {panel_patent_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                            {julia.patents.map((patent) => (
                                <div key={patent.title} className='work_item'> 
                                    <div className="work_overview">
                                        <div className="work_date">  <a href={patent.website}>{patent.title} </a> </div>
                                        <div className="work_date course"> {patent.authors.map((author) => (<div className="work_date course"> {author},</div>))} </div> 
                                        <div className="work_date course"> {patent.date}, </div> 
                                        <div className="work_date course"> {patent.docket} </div>
                                    </div>    
                                </div>
                            ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel expanded={this.state.openAwards} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openAwards ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openAwards: !this.state.openAwards})}>
                        {panel_awards_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                            {julia.awards.map((award) => (
                                <div key={award.title} className='work_item'> 
                                    <div className="work_overview">
                                        <div className="work_date"> {award.date} - </div> 
                                        <div className="work_date course"> {award.title},  </div>
                                        <div className="work_date">  <a href={award.website}>{award.awarder} </a> </div>
                                    </div>    
                                </div>
                            ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel expanded={this.state.openVol} >
                    <Panel.Heading 
                        id="work" 
                        className = {this.state.openVol ? 'openPanel' : 'closedPanel'}
                    > 
                    <Panel.Title 
                        toogle
                        onClick={() => this.setState({ openVol: !this.state.openVol})}>
                        {panel_volunteer_heading[this.props.language]}
                    </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                    <Panel.Body>
                        <Row>
                        {julia.volunteer.map((volunteer) => (
                            <div key={volunteer.company} className='work_item'> 
                                <div className="work_overview">
                                    <div className="work_date">  {volunteer.startDate} - </div>
                                    <div className="work_date course">  {volunteer.position}, </div>
                                    <div className="work_position course"> {volunteer.organization} </div>
                                    <div className="work_location course"> {panel_in[this.props.language]} {volunteer.location} </div>
                                </div>    
                            </div>
                        ))}  
                        </Row>
                    </Panel.Body>
                    </Panel.Collapse>
                </Panel>

 
            </Row>
        </div>
    )
  }
}

export default withRouter(CV);

// images/resume/doktorarbeit.png
// "summary": "The authors investigate the temperature dependence of exciton transfer from a single InGaN quantum well(QW) donor to colloidal CdS nanocrystal quantum dot acceptors and obtain an optimum transfer efficiency of 65% at 60K. Time and spectrally resolved measurements reveal that the transfer efficiency is dominated by the interplay between exciton localization and nonradiative recombination intrinsic to the QW.",
// "image": "images/publication/ET.jpg",
