import React from "react";
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Components/Home'
import Work from './Components/Work'
import About from './Components/About'
import Impressum from './Components/Impressum'
import Datenschutz from './Components/Datenschutz'


import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: 'EN'
    }
  }

  setLanguage = (id) => {
    this.setState({language: id})
  }

  render () {
  return (
      <div>
        <Router>
            <div className="app">
              <div className="screen">
                <Header
                  language = {this.state.language}
                  onSetLanguage = {this.setLanguage}
                />
                <Switch>
                  <Route exact path="/" > 
                    <Home 
                      language = {this.state.language}
                    />
                  </Route>

                  <Route exact path="/work" > 
                    <Work 
                      language = {this.state.language}
                    />
                  </Route>

                  <Route exact path="/about" > 
                    <About
                    language = {this.state.language}
                    />
                  </Route>

                  <Route exact path="/imprint" > 
                    <Impressum
                    language = {this.state.language}
                    />
                  </Route>

                  <Route exact path="/dataprivacy" > 
                    <Datenschutz
                    language = {this.state.language}
                    />
                  </Route>


                </Switch>
                <Footer
                 language = {this.state.language}
                />
              </div>
            </div>
          </Router>
      </div>
    )
  }
}

export default App;

/*
                  <Route exact path="/teaching" > 
                    <Teaching
                      language = {this.state.language}
                    />
                  </Route>
*/
