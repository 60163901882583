import React from 'react'
import { withRouter } from "react-router";
import { about_heading, about_pitch } from './Variables.js'
import CV from './CV.js'
import './About.css'
import '../App.css'

class About extends React.Component {

  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    return (
      <div>
        <div className="about_pitch"> 
          <img className="image-image" alt='' src="images/_W9A3142.jpg" />
          {about_pitch[this.props.language]}
        </div>  
        <div className="about_pitch"> 
          <CV language={this.props.language} />
        </div>  
        <div className="topSection">
          <div className="image-container">
            <img className="image-image" alt='' src="images/julia_aguila.jpg" />
            <div className="image-text">
              <div className="heading1"> {about_heading[this.props.language]} </div>
            </div>
        </div>
        </div> 

      </div>
    )
  }
}

export default withRouter(About);
