import React from 'react';
import { withRouter } from "react-router";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './Home.css'
import '../App.css'

library.add(fas)

class Datenschutz extends React.Component {

  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    return (
      <div>   
        <div className="topSection">
        </div>
        <div className="infoBox infoBoxWhite"> 
        <h1 class="adsimple-311294728">Datenschutzerklärung</h1>
<h2 id="einleitung-ueberblick" class="adsimple-311294728">Einleitung und Überblick</h2>
<p>Wir haben diese Datenschutzerklärung (Fassung 26.08.2021-311294728) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-311294728" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311294728" target="_blank" rel="noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
<strong class="adsimple-311294728">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
<p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong class="adsimple-311294728">Begriffe leserfreundlich erklärt</strong>, <strong class="adsimple-311294728">Links</strong> zu weiterführenden Informationen geboten und <strong class="adsimple-311294728">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
<h2 id="anwendungsbereich" class="adsimple-311294728">Anwendungsbereich</h2>
<p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
<ul class="adsimple-311294728">
<li class="adsimple-311294728">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li class="adsimple-311294728">Social Media Auftritte und E-Mail-Kommunikation</li>
<li class="adsimple-311294728">mobile Apps für Smartphones und andere Geräte</li>
</ul>
<p>
<strong class="adsimple-311294728">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 id="rechtsgrundlagen" class="adsimple-311294728">Rechtsgrundlagen</h2>
<p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-311294728" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311294728" target="_blank" rel="noreferrer">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li class="adsimple-311294728">
<strong class="adsimple-311294728">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
<li class="adsimple-311294728">
<strong class="adsimple-311294728">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
<li class="adsimple-311294728">
<strong class="adsimple-311294728">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li class="adsimple-311294728">
<strong class="adsimple-311294728">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul class="adsimple-311294728">
<li class="adsimple-311294728">In <strong class="adsimple-311294728">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-311294728">Datenschutzgesetz</strong>), kurz <strong class="adsimple-311294728">DSG</strong>.</li>
<li class="adsimple-311294728">In <strong class="adsimple-311294728">Deutschland</strong> gilt das <strong class="adsimple-311294728">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-311294728"> BDSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
<h2 id="kontaktdaten-verantwortliche" class="adsimple-311294728">Kontaktdaten des Verantwortlichen</h2>
<p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
<div>&nbsp;</div>
Julia Baldauf<br />
Münchnerstrasse 25<br />
 83624 Otterfing<br />
<br />
E-Mail: <a class="adsimple-311294728" href="mailto:office@musterfirma.com">mail@julia-baldauf.de</a>
<br />
Telefon: <span class="adsimple-311294728" > +49 163 1378833</span>
<br />
<div style={{textDecoration: "underline"}} onClick={() => {this.props.history.push('/imprint')}}>Impressum </div>
</p>
<h2 id="rechte-dsgvo" class="adsimple-311294728">Rechte laut Datenschutz-Grundverordnung</h2>
<p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul class="adsimple-311294728">
<li class="adsimple-311294728">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
<ul class="adsimple-311294728">
<li class="adsimple-311294728">zu welchem Zweck wir die Verarbeitung durchführen;</li>
<li class="adsimple-311294728">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li class="adsimple-311294728">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li class="adsimple-311294728">wie lange die Daten gespeichert werden;</li>
<li class="adsimple-311294728">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li class="adsimple-311294728">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
<li class="adsimple-311294728">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
<li class="adsimple-311294728">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
</ul>
</li>
<li class="adsimple-311294728">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
<li class="adsimple-311294728">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
<li class="adsimple-311294728">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
<li class="adsimple-311294728">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
<li class="adsimple-311294728">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
<ul class="adsimple-311294728">
<li class="adsimple-311294728">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
<li class="adsimple-311294728">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
<li class="adsimple-311294728">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
</ul>
</li>
<li class="adsimple-311294728">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
</ul>
<p>
<strong class="adsimple-311294728">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a class="adsimple-311294728" href="https://www.dsb.gv.at/?tid=311294728" target="_blank" rel="noreferrer">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-311294728 311294728" href="https://www.bfdi.bund.de" target="_blank" rel="noreferrer">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
<h2 id="bayern-datenschutzbehoerde" class="adsimple-311294728">Bayern Datenschutzbehörde</h2>
<p>
<strong class="adsimple-311294728">Landesbeauftragter für Datenschutz:</strong> Prof. Dr. Thomas Petri<br />
<strong class="adsimple-311294728">Adresse:</strong> Wagmüllerstr. 18, 80538 München<br />
<strong class="adsimple-311294728">Telefonnr.:</strong> 089/21 26 72-0<br />
<strong class="adsimple-311294728">E-Mail-Adresse:</strong> poststelle@datenschutz-bayern.de<br />
<strong class="adsimple-311294728">Website: </strong>
<a class="adsimple-311294728" href="https://www.datenschutz-bayern.de/?tid=311294728" target="_blank" rel="noreferrer">https://www.datenschutz-bayern.de/</a>
</p>
<h2 id="kommunikation" class="adsimple-311294728">Kommunikation</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-311294728">Kommunikation Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
&#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
&#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
&#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
<p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz vorschreibt.</p>
<h3 class="adsimple-311294728">Betroffene Personen</h3>
<p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
<h3 class="adsimple-311294728">Telefon</h3>
<p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-311294728">E-Mail</h3>
<p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-311294728">Online Formulare</h3>
<p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-311294728">Rechtsgrundlagen</h3>
<p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
<ul class="adsimple-311294728">
<li class="adsimple-311294728">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
<li class="adsimple-311294728">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
<li class="adsimple-311294728">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
</ul>
<h2 id="webhosting" class="adsimple-311294728">Webhosting</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-311294728">Webhosting Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
&#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
&#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-311294728">Was ist Webhosting?</h3>
<p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.</p>
<p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
<p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
<p>Zur Veranschaulichung:</p>
<p>
<img src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
</p>
<h3 class="adsimple-311294728">Warum verarbeiten wir personenbezogene Daten?</h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li class="adsimple-311294728">Professionelles Hosting der Website und Absicherung des Betriebs</li>
<li class="adsimple-311294728">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
<li class="adsimple-311294728">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
</ol>
<h3 class="adsimple-311294728">Welche Daten werden verarbeitet?</h3>
<p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul class="adsimple-311294728">
<li class="adsimple-311294728">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html?tid=311294728)</li>
<li class="adsimple-311294728">Browser und Browserversion (z. B. Chrome 87)</li>
<li class="adsimple-311294728">das verwendete Betriebssystem (z. B. Windows 10)</li>
<li class="adsimple-311294728">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
<li class="adsimple-311294728">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
<li class="adsimple-311294728">Datum und Uhrzeit</li>
<li class="adsimple-311294728">in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<h3 class="adsimple-311294728">Wie lange werden Daten gespeichert?</h3>
<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
<p>
<strong class="adsimple-311294728">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
<h3 class="adsimple-311294728">Rechtsgrundlage</h3>
<p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
<h2 id="amazon-web-services-aws-datenschutzerklaerung" class="adsimple-311294728">Amazon Web Services (AWS) Datenschutzerklärung</h2>
<p>Wir nutzen für unsere Website Amazon Web Services (AWS), unter anderem ein Webhosting-Anbieter. Dienstanbieter ist das amerikanische Unternehmen Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109, USA.</p>
<p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Amazon Web Services (AWS). Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus anderen Diensten von Amazon, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
<p>Mehr über die Daten, die durch die Verwendung von Amazon Web Services (AWS) verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf <a class="adsimple-311294728" href="https://aws.amazon.com/de/privacy/" target="_blank" rel="noreferrer">https://aws.amazon.com/de/privacy/</a>
<u>. </u>
</p>
<h2 id="social-media" class="adsimple-311294728">Social Media</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-311294728">Social Media Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
&#x1f4c5; Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-311294728">Was ist Social Media?</h3>
<p>Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
<h3 class="adsimple-311294728">Warum nutzen wir Social Media?</h3>
<p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln können.</p>
<p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
<p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
<p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<h3 class="adsimple-311294728">Welche Daten werden verarbeitet?</h3>
<p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
<p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw. Änderungen vornehmen.</p>
<p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
<h3 class="adsimple-311294728">
<span class="adsimple-311294728" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
</h3>
<p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
<h3 class="adsimple-311294728">Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<h3 class="adsimple-311294728">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-311294728">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-311294728">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 id="linkedin-datenschutzerklaerung" class="adsimple-311294728">LinkedIn Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-311294728">LinkedIn Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: die Daten werden grundsätzlich innerhalb von 30 Tagen gelöscht<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-311294728">Was ist LinkedIn?</h3>
<p>Wir nutzen auf unserer Webseite Social-Plug-ins des Social-Media-Netzwerks LinkedIn, der Firma LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Bei den Social-Plug-ins kann es sich um Feeds, das Teilen von Inhalten oder um die Verlinkung zu unserer LinkedIn-Seite handeln. Die Social-Plug-ins sind eindeutig mit dem bekannten LinkedIn-Logo gekennzeichnet und erlauben beispielsweise interessante Inhalte direkt über unsere Webseite zu teilen. Für den Europäischen Wirtschaftsraum und die Schweiz ist die Firma LinkedIn Ireland Unlimited Company Wilton Place in Dublin für die Datenverarbeitung verantwortlich.</p>
<p>Durch die Einbettung solcher Plug-ins können Daten an LinkedIn versandt, gespeichert und dort verarbeitet werden. In dieser Datenschutzerklärung wollen wir Sie informieren, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Sie die Datenspeicherung verwalten bzw. unterbinden können.</p>
<p>LinkedIn ist das größte soziale Netzwerk für Geschäftskontakte. Anders als beispielsweise bei Facebook konzentriert sich das Unternehmen ausschließlich auf den Aufbau geschäftlicher Kontakte. Unternehmen können auf der Plattform Dienstleistungen und Produkte vorstellen und Geschäftsbeziehungen knüpfen. Viele Menschen verwenden LinkedIn auch für die Jobsuche oder um selbst geeignete Mitarbeiter oder Mitarbeiterinnen für die eigene Firma zu finden. Allein in Deutschland zählt das Netzwerk über 11 Millionen Mitglieder. In Österreich sind es etwa 1,3 Millionen.</p>
<h3 class="adsimple-311294728">Warum verwenden wir LinkedIn auf unserer Website?</h3>
<p>Wir wissen wie beschäftigt Sie sind. Da kann man nicht alle Social-Media-Kanäle einzeln verfolgen. Auch wenn es sich, wie in unserem Fall, lohnen würde. Denn immer wieder posten wir interessante News oder Berichte, die es wert sind, verbreitet zu werden. Darum haben wir auf unserer Webseite die Möglichkeit geschaffen, interessante Inhalte direkt auf LinkedIn zu teilen bzw. direkt auf unsere LinkedIn-Seite zu verweisen. Wir betrachten eingebaute Social-Plug-ins als erweiterten Service auf unserer Webseite. Die Daten, die LinkedIn sammelt, helfen uns zudem mögliche Werbemaßnahmen nur Menschen zu zeigen, die sich für unser Angebot interessieren.</p>
<h3 class="adsimple-311294728">Welche Daten werden von LinkedIn gespeichert?</h3>
<p>Nur durch die bloße Einbindung der Social-Plug-ins speichert LinkedIn keine persönlichen Daten. LinkedIn nennt diese Daten, die durch Plug-ins generiert werden, passive Impressionen. Wenn Sie aber auf ein Social-Plug-in klicken, um beispielsweise unsere Inhalte zu teilen, speichert die Plattform personenbezogene Daten als sogenannte „aktive Impressionen“. Und zwar unabhängig, ob Sie ein LinkedIn-Konto haben oder nicht. Falls Sie angemeldet sind, werden die erhobenen Daten Ihrem Konto zugeordnet.</p>
<p>Ihr Browser stellt eine direkte Verbindung zu den Servern von LinkedIn her, wenn Sie mit unseren Plug-ins interagieren. So protokolliert das Unternehmen verschiedene Nutzungsdaten. Neben Ihrer IP-Adresse können das beispielsweise Anmeldungsdaten, Gerätinformationen oder Infos über Ihren Internet- bzw. Mobilfunkanbieter sein. Wenn Sie LinkedIn-Dienste über Ihr Smartphone aufrufen, kann auch Ihr Standort (nachdem Sie das erlaubt haben) ermittelt werden. LinkedIn kann diese Daten in „gehashter“ Form auch an dritte Werbetreibende weitergeben. Hashing bedeutet, dass ein Datensatz in eine Zeichenkette verwandelt wird. Dadurch kann man die Daten so verschlüsseln, dass Personen nicht mehr identifiziert werden können.</p>
<p>Die meisten Daten zu Ihrem Userverhalten werden in Cookies gespeichert. Das sind kleine Text-Dateien, die meist in Ihrem Browser gesetzt werden. Weiters kann LinkedIn aber auch Web Beacons, Pixel-Tags, Anzeige-Tags und andere Geräteerkennungen benutzen.</p>
<p>Diverse Tests zeigen auch welche Cookies gesetzt werden, wenn ein User mit einem Social-Plug-in interagiert. Die gefundenen Daten können keinen Vollständigkeitsanspruch erheben und dienen lediglich als Beispiel. Die folgenden Cookies wurden gesetzt, ohne bei LinkedIn angemeldet zu sein:</p>
<p>
<strong class="adsimple-311294728">Name:</strong> bcookie<br />
<strong class="adsimple-311294728">Wert:</strong> =2&amp;34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16311294728-<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Das Cookie ist ein sogenanntes „Browser-ID-Cookie“ und speichert folglich Ihre Identifikationsnummer (ID).<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> Nach 2 Jahren</p>
<p>
<strong class="adsimple-311294728">Name:</strong> lang<br />
<strong class="adsimple-311294728">Wert:</strong> v=2&amp;lang=de-de<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>
<strong class="adsimple-311294728">Name:</strong> lidc<br />
<strong class="adsimple-311294728">Wert:</strong> 1818367:t=1571904767:s=AQF6KNnJ0G311294728…<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Dieses Cookie wird zum Routing verwendet. Routing zeichnet die Wege auf, wie Sie zu LinkedIn gekommen sind und wie Sie dort durch die Webseite navigieren.<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> nach 24 Stunden</p>
<p>
<strong class="adsimple-311294728">Name:</strong> rtc<br />
<strong class="adsimple-311294728">Wert:</strong> kt0lrv3NF3x3t6xvDgGrZGDKkX<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Zu diesem Cookie konnten keine näheren Informationen in Erfahrung gebracht werden.<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> nach 2 Minuten</p>
<p>
<strong class="adsimple-311294728">Name:</strong> JSESSIONID<br />
<strong class="adsimple-311294728">Wert:</strong> ajax:3112947282900777718326218137<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Es handelt sich hier um ein Session-Cookie, das LinkedIn verwendet, um anonyme Benutzersitzungen durch den Server aufrecht zu erhalten.<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>
<strong class="adsimple-311294728">Name:</strong> bscookie<br />
<strong class="adsimple-311294728">Wert:</strong> &#8220;v=1&amp;201910230812…<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Bei diesem Cookie handelt es sich um ein Sicherheits-Cookie. LinkedIn beschreibt es als Secure-Browser-ID-Cookie.<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> nach 2 Jahren</p>
<p>
<strong class="adsimple-311294728">Name:</strong> fid<br />
<strong class="adsimple-311294728">Wert:</strong> AQHj7Ii23ZBcqAAAA…<br />
<strong class="adsimple-311294728">Verwendungszweck:</strong> Zu diesem Cookie konnten keine näheren Informationen gefunden werden.<br />
<strong class="adsimple-311294728">Ablaufdatum:</strong> nach 7 Tagen</p>
<p>
<strong class="adsimple-311294728">Anmerkung:</strong> LinkedIn arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unserem Test auch die beiden Google-Analytics-Cookies _ga und _gat erkannt.</p>
<h3 class="adsimple-311294728">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Grundsätzlich behaltet LinkedIn Ihre personenbezogenen Daten so lange, wie es das Unternehmen als nötig betrachtet, um die eigenen Dienste anzubieten. LinkedIn löscht aber Ihre personenbezogenen Daten, wenn Sie Ihr Konto löschen. In manchen Ausnahmefällen behaltet LinkedIn selbst nach Ihrer Kontolöschung einige Daten in zusammengefasster und anonymisierter Form. Sobald Sie Ihr Konto löschen, können andere Personen Ihre Daten innerhalb von einem Tag nicht mehr sehen. LinkedIn löscht die Daten grundsätzlich innerhalb von 30 Tagen. LinkedIn behält allerdings Daten, wenn es aus rechtlicher Pflicht notwendig ist. Daten, die keinen Personen mehr zugeordnet werden können, bleiben auch nach Schließung des Kontos gespeichert. Die Daten werden auf verschiedenen Servern in Amerika und vermutlich auch in Europa gespeichert.</p>
<h3 class="adsimple-311294728">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. In Ihrem LinkedIn-Konto können Sie Ihre Daten verwalten, ändern und löschen. Zudem können Sie von LinkedIn auch eine Kopie Ihrer personenbezogenen Daten anfordern.</p>
<p>So greifen Sie auf die Kontodaten in Ihrem LinkedIn-Profil zu:</p>
<p>Klicken Sie in LinkedIn auf Ihr Profilsymbol und wählen Sie die Rubrik „Einstellungen und Datenschutz“. Klicken Sie nun auf „Datenschutz“ und dann im Abschnitt „So verwendet LinkedIn Ihre Daten auf „Ändern“. In nur kurzer Zeit können Sie ausgewählte Daten zu Ihrer Web-Aktivität und Ihrem Kontoverlauf herunterladen.</p>
<p>Sie haben auch in Ihrem Browser die Möglichkeit, die Datenverarbeitung durch LinkedIn zu unterbinden. Wie oben bereits erwähnt, speichert LinkedIn die meisten Daten über Cookies, die in Ihrem Browser gesetzt werden. Diese Cookies können Sie verwalten, deaktivieren oder löschen. Je nachdem, welchen Browser Sie haben, funktioniert die Verwaltung etwas anders. Die Anleitungen der gängigsten Browser finden Sie hier:</p>
<p>
<a class="adsimple-311294728" href="https://support.google.com/chrome/answer/95647?tid=311294728" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
</p>
<p>
<a class="adsimple-311294728" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311294728" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
</p>
<p>
<a class="adsimple-311294728" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311294728" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
</p>
<p>
<a class="adsimple-311294728" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311294728" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
</p>
<p>
<a class="adsimple-311294728" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311294728" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
</p>
<p>Sie können auch grundsätzlich Ihren Browser dahingehend einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
<h3 class="adsimple-311294728">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-311294728">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-311294728">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>LinkedIn verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet LinkedIn von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten LinkedIn, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: <a class="adsimple-311294728" href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de" target="_blank" rel="follow noreferrer">https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de</a>.</p>
<p>Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch LinkedIn näherzubringen. Auf <a class="adsimple-311294728" href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/privacy-policy</a> erfahren Sie noch mehr über die Datenverarbeitung des Social-Media-Netzwerks LinkedIn.</p>
<h2 id="font-awesome-datenschutzerklaerung" class="adsimple-311294728">Font Awesome Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-311294728">Font Awesome Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: etwa IP-Adresse und und welche Icon-Dateien geladen werden<br />
Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: Dateien in identifizierbarer Form werden wenige Wochen gespeichert<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-311294728">Was ist Font Awesome?</h3>
<p>Wir verwenden auf unserer Website Font Awesome des amerikanischen Unternehmens Fonticons (307 S. Main St., Suite 202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseite aufrufen, wird die Web-Schriftart Font Awesome (im Speziellen Icons) über das Font Awesome Content Delivery Netzwerk (CDN) geladen. So werden die Texte bzw. Schriften und Icons auf jedem Endgerät passend angezeigt. In dieser Datenschutzerklärung gehen wir näher auf die Datenspeicherung und Datenverarbeitung durch diesen Service ein.</p>
<p>Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome ist eine Web-Schriftart, die speziell für Webdesigner und Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons mit Hilfe der Stylesheet-Sprache CSS nach Belieben skaliert und gefärbt werden. Sie ersetzen so alte Bild-Icons. Font Awesome CDN ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website zu laden. Dafür mussten wir nur eine kleine Code-Zeile in unsere Website einbinden.</p>
<h3 class="adsimple-311294728">Warum verwenden wir Font Awesome auf unserer Website?</h3>
<p>Durch Font Awesome können Inhalte auf unserer Website besser aufbereitet werden. So können Sie sich auf unserer Website besser orientieren und die Inhalte leichter erfassen. Mit den Icons kann man sogar manchmal ganze Wörter ersetzen und Platz sparen. Da ist besonders praktisch, wenn wir Inhalte speziell für Smartphones optimieren.  Diese Icons werden statt als Bild als HMTL-Code eingefügt. Dadurch können wir die Icons mit CSS genauso bearbeiten, wie wir wollen. Gleichzeitig verbessern wir mit Font Awesome auch unsere Ladegeschwindigkeit, weil es sich nur um HTML-Elemente handelt und nicht um Icon-Bilder. All diese Vorteile helfen uns, die Website für Sie noch übersichtlicher, frischer und schneller zu machen.</p>
<h3 class="adsimple-311294728">Welche Daten werden von Font Awesome gespeichert?</h3>
<p>Zum Laden von Icons und Symbolen wird das Font Awesome Content Delivery Network (CDN) verwendet. CDNs sind Netzwerke von Servern, die weltweit verteilt sind und es möglich machen, schnell Dateien aus der Nähe zu laden. So werden auch, sobald Sie eine unserer Seiten aufrufen, die entsprechenden Icons von Font Awesome bereitgestellt.</p>
<p>Damit die Web-Schriftarten geladen werden können, muss Ihr Browser eine Verbindung zu den Servern des Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt auch Daten darüber, welche Icon-Dateien wann heruntergeladen werden. Weiters werden auch technische Daten wie etwa Ihre Browser-Version, Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite übertragen.</p>
<p>Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:</p>
<ul class="adsimple-311294728">
<li class="adsimple-311294728">um Content Delivery Netzwerke zu optimieren</li>
<li class="adsimple-311294728">um technische Fehler zu erkennen und zu beheben</li>
<li class="adsimple-311294728">um CDNs vor Missbrauch und Angriffen zu schützen</li>
<li class="adsimple-311294728">um Gebühren von Font Awesome Pro-Kunden berechnen zu können</li>
<li class="adsimple-311294728">um die Beliebtheit von Icons zu erfahren</li>
<li class="adsimple-311294728">um zu wissen, welchen Computer und welche Software Sie verwenden</li>
</ul>
<p>Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch eine Standardschrift Ihres PCs verwendet. Nach derzeitigem Stand unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der Datenschutzabteilung von Font Awesome in Kontakt und geben Ihnen Bescheid, sobald wir näheres in Erfahrung bringen.</p>
<h3 class="adsimple-311294728">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Font Awesome speichert Daten über die Nutzung des Content Delivery Network auf Servern auch in den Vereinigten Staaten von Amerika. Die CDN-Server befinden sich allerdings weltweit und speichern Userdaten, wo Sie sich befinden. In identifizierbarer Form werden die Daten in der Regel nur wenige Wochen gespeichert. Aggregierte Statistiken über die Nutzung von den CDNs können auch länger gespeichert werden. Personenbezogene Daten sind hier nicht enthalten.</p>
<h3 class="adsimple-311294728">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Font Awesome speichert nach aktuellem Stand unseres Wissens keine personenbezogenen Daten über die Content Delivery Netzwerke. Wenn Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert werden, können Sie leider unsere Website nicht besuchen. Wenn Ihr Browser keine Web-Schriftarten erlaubt, werden auch keine Daten übertragen oder gespeichert. In diesem Fall wird einfach die Standard-Schrift Ihres Computers verwendet.</p>
<h3 class="adsimple-311294728">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Font Awesome eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong class="adsimple-311294728"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Font Awesome vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Font Awesome zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong class="adsimple-311294728">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Font Awesome gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Font Awesome. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Font Awesome, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
<p>Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a class="adsimple-311294728" href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> und die Hilfeseite unter <a class="adsimple-311294728" href="https://fontawesome.com/help?tid=311294728">https://fontawesome.com/help</a>.</p>
<p>Alle Texte sind urheberrechtlich geschützt.</p>
<p >Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" >Datenschutz Generator</a> von AdSimple</p>
      </div>
      </div>
    )
  }
}

export default withRouter(Datenschutz);
