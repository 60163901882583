import React from 'react';
import { withRouter } from "react-router";
import { home_heading, home_pitch} from './Variables.js'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './Home.css'
import '../App.css'

library.add(fas)

class Home extends React.Component {

  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    return (
      <div>   
        <div className="topSection">
          <div className="image-container">
            <img className="image-image" alt='' src="images/sunrise_karwendel.jpg" />
            <div className="image-text">
              <div className="heading1"> {home_heading[this.props.language]} </div>
              <div className="text1"> {home_pitch[this.props.language]} </div>
            </div>
          </div>     
        </div>
        <div className="infoBox infoBoxWhite"> 
          <div className="container planContainer">
            <div className="text2">
             {home_pitch[this.props.language]}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Home);

/*
  <div className="infoBox infoBoxWhite contentContainer"> 
    <WhyForWhoEvent 
      onGotoSignUp = {this.gotoSignUp}
    />         
  </div>
        <div className="infoBox contentContainer"> 
    <HowEvent />
  </div>

*/
