export const julia_en = {
	"basics": {
		"name": "Dr. Julia S. Baldauf",
		"label": "Physicist",
		"picture": "/images/resume/julia.png",
		"email": "julia.s.baldauf@gmail.com",
		"phone": "+49 163 1378833",
		"website": "",
		"summary":"I am a curious scientist who believes in data driven approaches to gain insight, make informed decisions and to educate, for example by leveraging cloud software to visualize and collect data. In addition, I am passionate about sustainability and I work best when my role and projects are aiming to create innovative and sustainable solutions instead of accepting and improving the status quo and established practices. This is what drives and motivates me to push boundaries and drive progress. Furthermore, I enjoy leading teams and projects with a hands-on mentality since I believe the best innovations start with collaboration, challenging and building on each others ideas.",		
		"summaryold": "I am a passionate scientist and leader. I am driven by my curiosity to see if processes and devices can be improved by questioning the status quo. My very structured and logical approach enables me to think projects through, from a high-level perspective all the way to the important details. I enjoy working as part of a team and creating a high preforming environment in which people support and respect each other.",		
		"location": "Otterfing, Germany",
		"linkedin": "https://www.linkedin.com/in/julia-baldauf-95716950?trk=hp-identity-name",
		"github": "https://github.com/chazarabriseis"
	},

	"work": [
	{
		"company": "",
		"location": "Otterfing, Germany",
		"position": "Freelancing Consultant",
		"website": "https://www.julia-baldauf.de/#/",
		"startDate": "January 2021",
		"endDate": "current",
		"summary": "Working as a freelance project manager, product owner and IT & Software consultant, utilizing my 11 years of professional experience I provide specialist advice and support to international clients on their digitalization journey. My focus is on project management, product owner roles, working on data science and software projects as well as developing innovation and strategy projects.",
		"highlights": [""]
	},
	{
		"company": "DAV Sektion Otterfing",
		"location": "Otterfing, Deutschland",
		"position": "1. Chair",
		"website": "https://www.dav-otterfing.de/#/",
		"startDate": "April 2022",
		"endDate": "jetzt",
		"summary": "As a volunteer, I look after the interests of the Otterfing section of the German Alpine Club.",
		"highlights": [""]
	},
	{
		"company": "Remote Social Butterfly",
		"location": "Otterfing, Germany",
		"position": "Founder and Full Stack Developer",
		"website": "",
		"startDate": "January 2021",
		"endDate": "December 2021",
		"summary": "Remote Social Butterfly is a data-driven communication organiser that will enable more successful networking. It pairs chat partners that make sense (i.e. not random) and lowers the barriers to people getting in touch by automating contact initiation.",
		"highlights": [""]
	},
	{
		"company": "Nanotemper Technologies",
		"location": "Munich, Germany",
		"position": "Team Lead Data Science",
		"website": "https://nanotempertech.com",
		"startDate": "February 2018",
		"endDate": "December 2020",
		"summary": "Nanotemper defined in its new strategy to become a Knowledge Provider in the life sciences sector. In order to lay the foundation to move towards this goal the Data Sciene Team was founded and I aligned its mission and agile projects to enable reaching this goal. This includes setting up an internal data management system and developing cloud services to establish a data stream from the customer side. I was the Product Owner of the Tycho.Cloud project, Nanotemper's first web based analysis software which can be directly connected to the Tycho device. I also was involved in developing new algorithms for new analysis apps.",
		"highlights": [""]
	},
	{
		"company": "Pollinate Energy",
		"location": "Lucknow, India",
		"position": "Guest Program Leader",
		"website": "https://pollinateenergy.org/our-programs",
		"startDate": "April 2017",
		"endDate": "May 2017",
		"summary": "Pollinate Energy is a social enterprise with the aim to reduce poverty in urban poor India through the access to solar lights and fans. After volunteering for Pollinate Energy in 2016 I returned this time to help run the professional fellowship program with 12 participants. I was guiding the teams throughout their projects helping the company to further establish their business in Uttar Pradesh. Working in urban poor India was a challenging experience which helped me to further grow as a person.",
		"highlights": [""]
	},
	{
		"company": "IBM Research Australia",
		"location": "Melbourne, Australia",
		"position": "Staff Researcher",
		"website": "http://www.research.ibm.com/labs/australia/index.shtml",
		"startDate": "July 2015",
		"endDate": "November 2016",
		"summary": "As a potential future leader of IBM Research Australia I was given a permanent position as a Staff Researcher. Restructuring of the Research Lab meant a stronger focus on healthcare, dissolving the natural resources efforts. I moved into the cognitive analytics team driving the agenda of deep learning in the healthcare and finance sectors. This involved up skilling to machine learning. I focused on neural networks and worked on semantic image labelling of optical coherence tomography data of arteries with stents. As a continuing member of the ‘Invention Disclosure Team’ team I helped to improve the labs innovative output and mentoring others.",
		"highlights": [""]
	}, {
		"company": "IBM Research Australia",
		"location": "Melbourne, Australia",
		"position": "Postdoctoral Researcher",
		"website": "http://www.research.ibm.com/labs/australia/index.shtml",
		"startDate": "July 2013",
		"endDate": "June 2015",
		"summary": "I initially joined IBM Research Australia to work in the Bionanosensor team, setting up the experimental facilities and helping to develop the strategic plan of the project. However as this project moved back to IBM Research Yorktown I moved in the  nanotechnology in natural resources team taking over the leadership of this team. Following this, I was enrolled in the IBM emerging talent program to help unfold my potential in this role. This team leadership role involved strategic planning across the Australia Research Lab, collaboration with global labs as well as working closely with clients. I successfully developed trustful relationships with clients by listening and understanding their needs and proposing research projects. This resulted in signing a ‘Joint Study Agreement’ with Metso to  study the froth flotation process. I also contributed technically studying nanoscale systems with molecular dynamic simulations. During this period I was appointed a member of the ‘Invention Disclosure Team’ to help drive the labs innovation output."
	}, {
		"company": "University of Melbourne",
		"location": "Melbourne, Australia",
		"position": "Research Assistant",
		"website": "http://www.nanoparticle.com/",
		"startDate": "February 2013",
		"endDate": "April 2013",
		"summary": "In my role as a research assistant I was responsible training new users in the techniques and application of various instruments which I built and developed during my time as a PhD student. It also involved knowledge transfer helping to formulate new research questions and projects.",
		"highlights": [""]
	}], 

	"studentPlacement" : [{
		"company": "Max-Planck Institute for Extraterrestrial Physics",
		"location": "Munich, Germany",
		"position": "Research Student",
		"website": "http://www.mpe.mpg.de/2169/en",
		"startDate": "January 2008",
		"endDate": "March 2008",
		"summary": "Testing X-Ray Detectors for the eROSITA project in Dr. Predehl’s group",
		"highlights": [""]
	}, {
		"company": "University of Hawaii",
		"location": "Honolulu, US",
		"position": "Research Project for Undergraduates",
		"website": "http://www.ifa.hawaii.edu/",
		"startDate": "June 2007",
		"endDate": "September 2007",
		"summary": "Analyzing spectra of supergiants to determine their metallicity in Prof. Kudritzki’s group",
		"highlights": [""]
	}, {
		"company": "Walther Meissner Institute",
		"location": "Munich, Germany",
		"position": "Research Student",
		"website": "http://www.wmi.badw.de/",
		"startDate": "October 2006",
		"endDate": "February 2007",
		"summary": "Designing a temperature controlled chamber for STM measurements in Prof. Hermann’s group",
		"highlights": [""]
	}, {
		"company": "University of Southampton",
		"location": "South Hampton, UK",
		"position": "Research Student",
		"website": "http://www.hybrid.soton.ac.uk/",
		"startDate": "July 2006",
		"endDate": "September 2006",
		"summary": "Setting up a temperature dependent TCSPC set-up, measuring energy transfer from QDs to a quantum well in Prof. Pavlos Lagoudakis’s Hybrid Photonics Group",
		"highlights": [""]
	}, {
		"company": "Max-Planck Institute for Plasma Physics",
		"location": "Munich, Germany",
		"position": "Research Student",
		"website": "http://www.ipp.mpg.de/2285/en",
		"startDate": "March 2005",
		"endDate": "April 2015",
		"summary": "Simulation and Analysis of the temperature profile in the Tokamak ASDEX in Prof. Zohm's group",
		"highlights": [""]
	}, {
		"company": "Siemens",
		"location": "Munich, Germany",
		"position": "Student Placement",
		"website": "http://www.sta.siemens.com/",
		"startDate": "November 2002",
		"endDate": "January 2005",
		"summary": "Testing work capacity of MOSFETs",
		"highlights": [""]
	}, {
		"company": "Siemens",
		"location": "Beeston, UK",
		"position": "Student Placement",
		"website": "http://www.siemens.co.uk/en/index.htm",
		"startDate": "July 2002",
		"endDate": "September 2002",
		"summary": "Translating a phone user interface ",
		"highlights": [""]
	}],

	"education": [{
		"institution": "University of Melbourne",
		"location": "Melbourne, Australia",
		"image" : "images/resume/doktorarbeit.png",
		"area": "Physical Chemistry",
		"group": "Nanoparticle Group",
		"supervisor": "Prof. Mulvaney",
		"website": "http://www.nanoparticle.com/",
		"studyType": "PhD",
		"thesisTitle": "The effects of External Fields on the Optical Properties of Nanocrystals",
		"description": "Excitonics and plasmonics are exciting fields in  nano science due to the outstanding optical properties evolving in the nanometer scale of semiconductor and metal materials. This makes nanocrystals promising candidates for applications like for example LEDs, solar cells and bio labels, however their optical properties are not fully understood yet. In my thesis I focused on CdSe/CdS/ZnS and gold nanocrystals. In order to further understand their optical properties I constructed a widefield microscope where I was able to apply different atmospheres to the samples, showing there is a strong correlation between the atmosphere and the optical behaviour of nanocrystals. This was confirmed and further studied using a confocal microscope allowing single nanocrystal exciton lifetime measurements. Furthermore, I developed techniques to overlap widefield/darkfield images with atomic force microscope images and scanning electron images to gain insights in the structural dependence on the optical properties.  I also developed devices with which I was able to apply electric fields to single nanocrystals and ensembles in order to study charging effects on nanocrystals.",
		"startDate": "June 2009",
		"endDate": "February 2013",
		"courses": [""]
	}, {
		"institution": "Ludwig-Maximillians University",
		"location": "Munich, Germany",
		"image" : "images/resume/diplomarbeit.png",
		"area": "Physics",
		"group": "Photonics and Optoelectronics Group",
		"website": "http://www.phog.physik.lmu.de/",
		"supervisor": "Prof. Feldmann",
		"studyType": "Masters",
		"thesisTitle": "Size dependent Luminescence of Semiconductor Nanocrystals",
		"description": "Nano science creates a wealth of new materials, during my master degree I investigated size dependence on the properties of  semiconductor tetrapods using a widefield setup with a cryostat sample chamber.",
		"startDate": "October 2002",
		"endDate": "March 2009",
		"courses": [""]
	}, {
		"institution": "University of Seville",
		"location": "Seville, Spain",
		"image" : "",
		"area": "Physics",
		"website": "http://www.us.es/eng/centres/uscentres/centro_10",
		"group": "",
		"supervisor": "",
		"studyType": "Coursework",
		"startDate": "September 2005",
		"endDate": "March 2006",
		"courses": [""]
	},{
		"institution": "Gymnasium Oberhaching",
		"location": "Oberhaching, Germany",
		"image" : "",
		"area": "",
		"website": "http://www.gymnasium-oberhaching.de/",
		"group": "",
		"supervisor": "",
		"studyType": "Abitur",
		"startDate": "1997",
		"endDate": "2009",
		"courses": [""]
	},{
		"institution": "Grundschule Otterfing",
		"location": "Otterfing, Germany",
		"image" : "",
		"area": "",
		"website": "http://www.schule-otterfing.de/",
		"group": "",
		"supervisor": "",
		"studyType": "Coursework",
		"startDate": "1990",
		"endDate": "1996",
		"courses": [""]
	}],

	"courses": [
		{
			"date": "February 2021",
			"website":"https://www.scrum.org/user/791655",
			"school":"Scrum.org",
			"name": "Professional Scrum Product Owner I Certification"
	},
	{
		"date": "October 2020",
		"website":"https://acloudguru.com/course/aws-certified-cloud-practitioner-2020",
		"school":"AWS",
		"name": "AWS Cloud Practitioner 2020 Course"
},
		{
			"date": "March 2020",
			"website":"",
			"school":"Udemy",
			"name": "Java Script: Understanding the weird parts"
	},
		{
			"date": "November 2019",
			"website":"",
			"school":"Udemy",
			"name": "React: The complete Guide"
	},{
		"date": "July 2019",
		"website":"",
		"school":"Agile Coach",
		"name": "Scrum Workshop"
	},{
		"date": "August 2016",
		"website":"https://www.udacity.com/course/javascript-basics--ud804",
		"school":"Udacity",
		"name": "JavaScript Basics"
	}, {
		"date": "August 2016",
		"website":"https://www.udacity.com/course/intro-to-html-and-css--ud304",
		"school":"Udacity",
		"name": "Intro to HTML and CSS"
	}, {
		"date": "July 2016",
		"website":"https://www.udacity.com/course/web-development--cs253",
		"school":"Udacity",
		"name": "Web Development"
	}, {
		"date": "July 2016",
		"school": "",
		"website": "http://medialab.futureearth.org/anthronaut/melbourne/",
		"name": "VR Hackathon on urban sustainability in Melbourne"
	}, {
		"date": "June 2016",
		"website":"https://www.udacity.com/course/software-development-process--ud805",
		"school":"Udacity",
		"name": "Software Development Process"
	}, {
		"date": "October 2015",
		"website":"http://neuralnetworksanddeeplearning.com/chap1.html",
		"school":"Online",
		"name": "Neural Networks and deep Learning Book"
	}, {
		"date": "July 2015",
		"website":"http://resbaz.github.io/2015-07-13-unimelb/",
		"school":"ResBaz, University of Melbourne",
		"name": "Python Course"
	}, {
		"date": "March 2015",
		"website":"",
		"school":"IBM Emerging Leaders",
		"name": "Leading High Performance"
	}, {
		"date": "March 2015",
		"website":"",
		"school":"IBM Emerging Leaders",
		"name": "Strategy and Finance for Leaders"
	}, {
		"date": "October 2014",
		"website":"",
		"school":"IBM Emerging Leaders",
		"name": "Leading Relationship"
	}, {
		"date": "August 2014",
		"website":"https://www.vlsci.org.au/news/MDnews0713",
		"school":"VLSCI, University of Melbourne",
		"name": "MD Workshop"
	}, {
		"date": "March 2014",
		"website":"",
		"school":"IBM",
		"name": "Leadership in a Project Team Environment"
	}, {
		"date": "October 2013",
		"website":"https://www.coursera.org/learn/creativity-innovation",
		"school":"Coursera",
		"name": "Creativity, Innovation and Change"
	}, {
		"date": "September 2013",
		"website":"http://www.virology.ws/2013/12/03/virology-at-coursera/",
		"school":"Coursera",
		"name": "Virology1 – How viruses work"
	}, {
		"date": "August 2013",
		"website":"https://www.coursera.org/learn/drug-development",
		"school":"Coursera",
		"name": "Drug Discovery, Development & Commercialization"
	}, {
		"date": "July 2013",
		"website":"",
		"school":"Coursera",
		"name": "Cell Biology"
	}],

	"publications": [{
		"title": "Temperature Dependence of Exciton Transfer in Hybrid  Quantum Well/Nanocrystal Heterostructures",
		"authors": ["S. Rohrmoser", "J. Baldauf", "S. Sapra", "A. Eychmüller", "I. M. Watson", "R. T. Harley", "P. G. Lagoudakis"],
		"journal": "Applied Physics Letters",
		"volume": "91",
		"year": "2007",
		"website": "http://scitation.aip.org/content/aip/journal/apl/91/9/10.1063/1.2776865",
		"summary": "The authors investigate the temperature dependence of exciton transfer from a single InGaN quantum well(QW) donor to colloidal CdS nanocrystal quantum dot acceptors and obtain an optimum transfer efficiency of 65% at 60K. Time and spectrally resolved measurements reveal that the transfer efficiency is dominated by the interplay between exciton localization and nonradiative recombination intrinsic to the QW.",
		"image": "images/publication/ET.jpg",
		"comment": ""
	}, {
		"title": "Spatio-temporal dynamics of coupled electrons and holes in nanosize CdSe-CdS semiconductor tetrapods",
		"authors": ["C. Mauser", "E. Da Como", "J. Baldauf", "A. L. Rogach", "J. Huang", "D. V. Talapin", "J. Feldmann "],
		"journal": "Physical Review B",
		"volume": "82",
		"year": "2010",
		"website": "http://journals.aps.org/prb/abstract/10.1103/PhysRevB.82.081306",
		"summary": "We report on coupled electron-hole transfer, Coulomb drag, in CdSe/CdS semiconductor nanotetrapods. We demonstrate that photoexcited holes can either be transferred to the CdSe core or become trapped in one of the CdS arms. By combining time-resolved pump-probe and photoluminescence measurements we investigate how the Coulomb potential drags the electron to the hole localization site. As supported by effective-mass calculations taking into account Coulomb effects we conclude that the hole dynamics determines the fate of the electron in a coupled dynamics.",
		"image": "images/publication/TP.png",
		"comment": "Selected for the Virtual Journal of Nanoscale Science and Technology 22 (2010) and the Virtual Journal of Ultrafast Science 9 (2010)"
	}, {
		"title": "Synthesis of Quantum Dot Doped Chalcogenide Glasses – via Sol-gel Processing",
		"authors": ["B. Mashford", "J. Baldauf", "T. Nguyen", "A. Funston", "P. Mulvaney "],
		"journal": "Journal of Applied Physics",
		"volume": "109",
		"year": "2011",
		"website": "http://scitation.aip.org/content/aip/journal/jap/109/9/10.1063/1.3579442",
		"summary": "Semiconductorquantum dots(QDs) are used to dope wide-bandgap chalogenide glasses via sol-gel processing. Such chalcogenides enhance surface passivation of the quantum dots, as evidenced by the increased PL emissions of both core and core shell species used, while a ZnOglass leads to irreversible oxidation of the embedded quantum dots. The embedded QDs are photostable.",
		"image": "images/publication/LED.png",
		"comment": ""
	}, {
		"title": "Improved thermal stability of Au nanorods by use of photosensitive layered titanates for gas sensing application",
		"authors": ["Antonello", "E. Della Gaspera", "J. Baldauf", "G. Mattei", "A. Martucci "],
		"journal": "Journal of Materials Chemistry",
		"volume": "21",
		"year": "2011",
		"website": "http://pubs.rsc.org/en/content/articlelanding/2011/jm/c1jm12537k#!divAbstract",
		"summary": "In this work, we stabilize the cylindrical shape of Au nanorods under temperatures up to 400 °C. This has been accomplished by using photosensitive-layered titanates, which can be densified and stiffened by UV irradiation. The effect of interaction with specific gas analytes on the plasmon resonances of Au nanorods in TiO2 anatase films has been exploited for optical gas sensing applications",
		"image": "images/publication/NR.gif",
		"comment": ""
	}, {
		"title": "Au Nanoparticle Monolayers Covered with Sol Gel Oxide Thin Films: Optical and Morphological Study",
		"authors": ["E. Della Gaspera", "M. Karg", "J. Baldauf", "J. Jasieniak", "G. Maggioni", "A. Martucci"],
		"journal": "Langmuir ",
		"volume": "27",
		"year": "2011",
		"website": "http://pubs.acs.org/doi/abs/10.1021/la2032829",
		"summary": "In this work, we provide a detailed study of the influence of thermal annealing on submonolayer Au nanoparticle deposited on functionalized surfaces as standalone films and those that are coated with sol–gel NiO and TiO2 thin films. Optical sensing tests for ethanol vapor are presented as one possible application, showing reversible sensing dynamics and confirming the effect of Au nanoparticles in increasing the sensitivity.",
		"image": "images/publication/ML.gif",
		"comment": ""
	}, {
		"title": "The surface plasmon modes of self-assembled gold nanocrystals",
		"authors": ["S. Barrow", "X. Wei", "J. Baldauf", "A. Funston", "P. Mulvaney"],
		"journal": "Nature Communications",
		"volume": "3",
		"year": "2012",
		"website": "http://www.nature.com/articles/ncomms2289",
		"summary": "This study presents a two-stage process for fabricating well-defined and highly symmetric, 3D gold nanocrystal structures, including tetrahedra, 3D pentamers and 3D hexamers. Polarized scattering spectra are used to elucidate the plasmon modes present in each structure, and these are compared with computational models. We conclude that self-assembly of highly symmetric, polarization-independent structures with interparticle spacings of order 0.5 nm can now be fabricated.",	
		"image": "images/publication/GNP.jpg",
		"comment": ""
	}, {
		"title": "Effect of different atmosphere on the QD luminescence",
		"authors": ["S. Murphy", "K. Boldt", "J. Baldauf", "P. Mulvaney"],
		"journal": "Nano Letters",
		"volume": "submitted",
		"year": "",
		"website": "",
		"summary": "The photo-luminescence properties of CdSe/CdS/ZnS quantum dots are highly dependent on their environment. Herein, we investigate the effects of controlled water vapour on the QD PL prior as well as during photo-excitation in both the presence and absence of oxygen using a wide-field setup with a gas-controlled sample chamber.",
		"image": "images/publication/ATMOS.png",
		"comment": ""
	}, {
		"title": "Nanosensors for next generation drug screening",
		"authors": ["Sr. K. Kannam","M. T. Downton","N. Gunn","S. C. Kim","P. R. Rogers","C. Schieber","J. S. Baldauf","J. M. Wagner","D. Scott","R. Bathgate","S. Skafidas","S. Harrer"],
		"journal": "SPIE Proceedings ",
		"volume": "8923",
		"year": "2013",
		"website": "",
		"summary": "One promising path for future drug screening technologies is to examine the binding of ligands to target proteins at the single molecule level by passing them through nanometer sized pores and measuring the change in pore current during translocation. With the aim of evaluating such technologies we perform virtual experiments on the translocation of proteins through silicon nitride nanopores.",	
		"image": "images/publication/Nanopore.png",
		"comment": ""
	}, {
		"title": "Tunable piezo-driven sieve consisting of a multi-nanopore chip",
		"authors": ["J. Baldauf", "C. Schieber", "S. Harrer", "J. Wagner"],
		"journal": "IBM Technical Disclosure Bulletin",
		"volume": "",
		"date": "08/07/2014",
		"website": "",
		"summary": "Fabrication of solid state nanopores results in a fixed pore diameter allowing only the translocation of molecules of a certain size. As the current change is largely a function of the volume of a molecule, smaller molecules cannot be detected due to a small current change and larger molecules will not be able to translocate at all. We propose a tunable multi-nanopore array which can be continuously closed and opened in a reversible manner. The idea is to employ piezoelectric materials which allow nanometre precision in opening and closing of the pore.",
		"image": "images/patent/piezo.png",
		"comment": ""
	}, {
		"title": "Fullerene-fullerene interactions in water: A molecular dynamic study",
		"authors": ["A. Makarucha", "J. Baldauf", "M. Downton", "G.Yiapanis"],
		"journal": "Journal of Physical Chemistry B",
		"volume": "120 (42)",
		"year": "2016",
		"website": "http://pubs.acs.org/doi/abs/10.1021/acs.jpcb.6b07471",
		"summary": "In this paper, we used explicit solvent molecular dynamic simulations to show that size- and shape-dependent distortions of the water layer near the surface affects the aggregation of nested fullerenes in water. Spherically shaped particles transition quickly into contact, while larger fullerenes, characterized by a lower sphericity, cluster at a much slower rate.",	"image": "images/publication/TOC.png",
		"comment": ""
	}, {
		"title": "Simulations of Graphitic Nanoparticles at Air-Water Interfaces",
		"authors": ["G.Yiapanis", "A. Makraucha", "J. Baldauf", "M. Downton"],
		"journal": "Nanoscale",
		"volume": "47",
		"year": "2016",
		"website": "http://pubs.rsc.org/en/Content/ArticleLanding/2016/NR/C6NR06475B#!divAbstract",
		"summary": "The free energy associated with transferring a set of fullerene particles through a finite water layer is calculated using explicit solvent molecular dynamic simulations. The particles considered in- clude single-shelled (single-wall) and multi-shelled (nano-onion) fullerenes ranging from 6 to 28 Angstrom in radius. Corresponding changes in energy suggest a stronger affinity of carbon nano-onions for water compared to their single-shelled analogues.",	
		"image": "images/publication/FWI.png",
		"comment": ""
	}, {
		"title": "Real-time detection of emergency situations via cognitive analysis of audio data streams",
		"authors": ["J. Baldauf", "B. Mashford", "A. Makarucha"],
		"journal": "IBM Technical Disclosure Bulletin",
		"volume": "",
		"year": "2016 ",
		"website": "",
		"summary": "Emergency detection in crowded or isolated environments can be difficult task for staff assigned with the responsibility of responding in an emergency. Examples of professions where a cognitive detection system may be useful, include: crowd control security, commuter train drivers, museum attendants, etc. In our proposed method, an array of microphones are setup within a commuter train or like environment, the recorded audio  is streamed to a low-power computer device and analyzed to detect stress-related vocal patterns (e.g. screaming, shouting) to assist identifying emergencies.",
		"image": "images/patent/audiosystem.png",
		"comment": ""
	} , {
		"title": "A system and method for gold deposit identification",
		"authors": ["J. Baldauf", "A. Bojovschi", "B. Mashford", "G. Yiapanis", "A. Makarucha"],
		"journal": "IBM Technical Disclosure Bulletin",
		"volume": "",
		"year": "2016",
		"website": "",
		"summary": "Processes, that involve ultrasound sensors and drilling boreholes to sample the ground, required for mining deposits identification are expensive, and difficult to carry out on mountainous train. The present invention proposes a system and method for detecting mineral deposits and chemical or biological contamination of the soil. The system uses automated systems to locate the area of interest and to map its characteristics. The proposed invention relies on unmanned aerial vehicles and data analytics to characterize geological characteristics of earth by analyzing sensing data pertaining to tree or plats samples.",
		"image": "images/patent/mineraldeposit.png",
		"comment": ""
	}, ],

	"patents": [{
		"title": "Directed surface functionalization on selected surface areas of topographical features with nanometer resolution",
		"authors": ["J. Baldauf", "C. Schieber", "S. Harrer"],
		"date": "10/15/2013",
		"docket": "YOR920130807US1",
		"location": "US",
		"website": "https://www.google.com.au/patents/US20150225838?dq=Directed+surface+functionalization+on+selected+surface+areas+of+topographical+features+with+nanometer+resolution&hl=en&sa=X&ved=0ahUKEwjSmZ_R2I3PAhVRpIMKHT8vBl4Q6AEIGzAA",
		"summary": "A method for making a single molecule receptor in a nanopore structure includes depositing a material by a physical vapor deposition (PVD) technique onto a selected interior surface of a nanochannel and functionalizing a surface of the material with a chemical compound having at least two functional groups. The material forms a patch having a diameter of about 3 to about 10,000 nanometers (nm).",
		"image": "images/patent/NP.jpg",
		"comment": ""
	}, {
		"title": "Nano fluidic sensor comprising spatially separated functional sensing components",
		"authors": ["J. Baldauf", "C. Schieber", "S. Harrer"],
		"date": "10/15/2013",
		"docket": "YOR920130891US1",
		"location": "US",
		"website": "https://www.google.com.au/patents/US9303310?dq=Nano+fluidic+sensor+comprising+spatially+separated+functional+sensing+components&hl=en&sa=X&ved=0ahUKEwid3PWi2Y3PAhUi24MKHXI-DFkQ6AEIHTAA",
		"summary": "A method for making multiple single molecule receptors in a nanopore structure includes depositing a first material and a second material by a physical vapor deposition (PVD) technique onto different selected interior surfaces of a nanochannel and functionalizing a surface of the first material, the second material, or both the first and second materials with a chemical compound having at least two functional groups. The first and second materials can be the same or different and form patches having diameters of about 1 to about 100 nanometers (nm).",		
		"image": "images/patent/SAM.png",
		"comment": ""
	}, {
		"title": "Detection of translocation event using graphene-based nanopore assemblies",
		"authors": ["J. Baldauf", "M. Downton", "N. Gunn", "S. Harrer", "S. Kannam", "C. Schieber", "J. Wagner"],
		"date": "06/26/2014",
		"docket": "YOR920140181US1in",
		"location": "US",
		"website": "https://www.google.com.au/patents/WO2015198242A1?cl=en&dq=Detection+of+translocation+event+using+grapheme-based+nanopore+assemblies&hl=en&sa=X&ved=0ahUKEwiQspLh243PAhXJ8YMKHf52DV8Q6AEIGzAA",
		"summary": "Translocation events are sensed using composite nanopore assemblies including nanopores formed in graphene sheets. Single molecule detection and characterization and multi-molecule characterization and identification are provided using such assemblies. Multiple electrodes associated with nanofluidic sensors facilitate detection of ionic current through a nanopore as well as tunneling currents. Current signals of individual molecules are estimated from the combination of an ionic current signal through the nanopore and tunneling current signals obtained at specific locations within the nanopore.",
		"image": "images/patent/grapheneNP.png",
		"comment": ""
	}, {
		"title": "Engulfed nano/micro bubbles for improved recovery of large particles in a flotation cell",
		"authors": ["J. Baldauf", "A. Bojovschi", "S. Moore "],
		"date": "07/14/2015",
		"docket": "YOR920140437US1",
		"location": "US",
		"website": "https://patents.google.com/patent/US20170014834A1/en",
		"summary": "The present invention provides the use of a multitude of nano/micro bubbles or a combination thereof situated on the surface of a solid particle to increase its attachment to a larger carrier bubble. They enhance the adhesion between the two entities thereby facilitating the flotation of larger particles.The present invention provides a method and apparatus for floating particles larger than about 100 micrometer (for minerals) and 600 micrometer (for coal), which is the the upper size limit of current technologies.",		
		"image": "images/patent/engulfedNB.png",
		"comment": ""
	}, {
		"title": "Nanobubbles for enhanced interaction between solids and gas volumes",
		"authors": ["J. Baldauf", "C. Schieber", "P. Rogers", "A. Bojovschi", "S. Moore"],
		"date": "08/10/2015",
		"docket": "YOR920150002US1",
		"location": "US",
		"website": "https://patents.google.com/patent/US20170043356A1/en",
		"summary": "Froth flotation is a process used to selectively separate hydrophobic from hydrophilic materials. We propose a new mechanism by which nanobubbles, formed on the hydrophobic surface, create an increased attachment force through a capillary bridge between the hydrophobic surface and a collecting air bubble, resulting in the ability to increase the recovery of coarse particles.",		
		"image": "images/patent/nanobubble.png",
		"comment": ""
	}, {
		"title": "Flowfield sensors for monitoring liquid flow",
		"authors": ["J. Baldauf", "D. Beurle", "M. Downton", "S. Moore", "C. Schieber", "G. Yiapanis"],
		"date": "09/07/2015",
		"docket": "YOR920150333US1",
		"location": "US",
		"website": "https://patents.google.com/patent/US20170066660A1/en",
		"summary": "This invention introduces a new sensor containing a MEMS (micro electro-mechanical systems) accelerometer and gyroscope that can be placed into a flow and record its acceleration as it moves around within the flow field.  Upon recovery of the sensor, the acceleration data can be retrieved via removable onboard memory and post-processed with custom software to produce a trajectory through space and time helping to understand the details of the fluid dynamics.",
		"image": "images/patent/sensor.png",
		"comment": ""
	}, {
		"title": "Froth flotation with anisotropic particle collectors",
		"authors": ["J. Baldauf", "C. Schieber", "A.  Bojovschi", "B. Mashford", "G. Yiapanis", " M. Downton"],
		"date": "12/22/2015",
		"docket": "YOR920151300US1",
		"location": "US",
		"website": "https://patents.google.com/patent/US20170173594A1/en",
		"summary": "This invention describes a process for separating particulate material from a mixture during froth flotation by treating the mixture with anisotropic (janus) particles. The janus particle plays the role of collector, requiring that one part of the anisotropic particle be designed to preferentially adsorb to the mineral surface (selective adsorption) while the remaining part of the janus particle be designed to selectively adsorb to the air bubble with the use of hydrocarbon chains or other hydrophobic domains of sufficient size.",		
		"image": "images/patent/anisotropic.png",
		"comment": ""
	}, {
		"title": "Optimal distributed energy resource management system",
		"authors": ["J. Baldauf", "B. Mashford", "J. De Hoog", "K. Abdulla"],
		"date": "07/28/2016",
		"docket": "YOR920160837US1",
		"location": "US",
		"website": "",
		"summary": "A control system that takes a holistic approach to optimal management and control of generation, energy storage, and thermal storage components to run a solar PV system in the best possible way according to desired operation mode.",	
		"image": "images/patent/energy.png",
		"comment": ""
	}, {
		"title": "System, method and computer program product for ensemble-based cognitive online health system for effective disease diagnosis",
		"authors": ["J. Baldauf", "L. Ghahremanlou", "F. Jalali", "M. Salehi"],
		"date": "08/31/2016",
		"docket": "YOR920161175US1",
		"location": "US",
		"website": "",
		"summary": "Patients often complain about the lack of precise diagnosis of their medical problems as well as the lengthy process of finding a matched specialist for their disease. We solve the problem of uncertainty in diagnosis of special diseases by proposing an ensemble-based diagnosis system. To make the diagnosis process more robust, an online consulting system is presented with the use of diverse physicians and cognitive intelligent agents.",		
		"image": "images/patent/Schema.png",
		"comment": ""
	}, {
		"title": "User-Friendly navigation system",
		"authors": ["J. Baldauf", "F. Jalali", "B. Mashford", "M. Salehi"],
		"date": "03/5/2017",
		"docket": "YOR920161808US1",
		"location": "US",
		"website": "",
		"summary": "The exemplary embodiments of this invention relate generally to navigation systems and, more specifically, to a navigation system that provides user-friendly instructions to a user of the navigation system.",		
		"image": "images/patent/navigation.png",
		"comment": ""
	}, {
		"title": "Intravescular catheter for modeling blood vessels",
		"authors": ["J. Baldauf", "D. Beurle", "M. Downton", "K. Halupka", "S. Moore", "C. Schieber"],
		"date": "05/10/2017",
		"docket": "YOR920161273US1",
		"location": "US",
		"website": "",
		"summary": "",		
		"image": "",
		"comment": ""
	}, {
		"title": "Intravescular catheter including markers",
		"authors": ["J. Baldauf", "D. Beurle", "M. Downton", "K. Halupka", "S. Moore", "C. Schieber"],
		"date": "05/20/2017",
		"docket": "YOR920161274US1",
		"location": "US",
		"website": "",
		"summary": "",		
		"image": "",
		"comment": ""
	}],

	"awards": [{
		"date": "May 2016",
		"title": "High Value Patent Application Award",
		"awarder": "IBM",
		"summary": "",
		"website":""
	}, {
		"date": "June 2014, August 2015, Macrh 2016",
		"title": "First, Second, Third Patent Plateau Award",
		"awarder": "IBM",
		"summary": "",
		"website":""
	}, {
		"date": "March 2014",
		"title": "Emerging Talent Program",
		"awarder": "IBM",
		"summary": "",
		"website":""
	}, {
		"date": "April 2013",
		"title": "Most Valuable Player AAA, Beachvolleyball Season 2012/13",
		"awarder": "VicBeach",
		"summary": "",
		"website":"http://www.vicbeach.com.au"
	}, {
		"date": "June 2012",
		"title": "Young Nanoscience Ambassador Award",
		"awarder": "Australian Nanotechnology Network",
		"summary": "PhD student Julia Baldauf of the Mulvaney Group won the Victorian Young Nanotechnology Ambassador Award given by the Australian Nanotechnology Network for her enthusiastic involvement in promoting science and science education. Julia has been actively engaged in outreach activities including visits to metropolitan and regional schools to inspire students about nanotechnology and more broadly science education. ",
		"website":"http://www.ausnano.net/content/young_ambass_awards"
	}, {
		"date": "June 2011",
		"title": " Overseas Research Experience Scholarship",
		"awarder": "University of Melbourne",
		"summary": "",
		"website":"https://studenteforms.app.unimelb.edu.au/apex/f?p=153:2:0:::2:P2_ID:462"
	}, {
		"date": "June 2009",
		"title": "International Postgraduate Research Scholarship and Science Faculty Scholarship",
		"awarder": "University of Melbourne",
		"summary": "",
		"website":"http://arts.unimelb.edu.au/scholarships/international-postgraduate-research-scholarship-iprs"
	}, {
		"date": "May 2007",
		"title": "Travel Scholarship",
		"awarder": "German Academic Exchange Service (DAAD)",
		"summary": "",
		"website":"https://www.daad.org/scholarship"
	}, {
		"date": "May 2007",
		"title": "Research Experiences for Undergraduates (REU) Scholarship",
		"awarder": "University of Hawaii",
		"summary": "",
		"website":"http://www2.ifa.hawaii.edu/newsletters/article.cfm?a=352&n=30"
	}, {
		"date": "September 2005",
		"title": "ERASMUS Travel Scholarship",
		"awarder": "Ludwig-Maximillians University",
		"summary": "",
		"website":"http://www.uni-muenchen.de/studium/studium_int/auslandsstudium/austausch/erasmus/progr_erklaerung/index.html"
	}, {
		"date": "April 2004",
		"title": "Travel Award",
		"awarder": "Wilhelm and Else Hereaus Foundation",
		"summary": "",
		"website":"http://www.we-heraeus-stiftung.de/index.php?option=com_content&view=article&id=132&Itemid=563"
	}, {
		"date": "October 2002",
		"title": "Young Ladies of Technology Network Member (YOLANTE)",
		"awarder": "Siemens",
		"summary": "",
		"website":"http://www.siemens.de/jobs/studenten/studentenprogramme/yolante/seiten/default.aspx"
	}],

	"volunteer": [
		{
			"startDate": "June 2018",
			"organization": "DPG",
			"website": "https://pollinateenergy.org/our-programs/pf/",
			"summary": "",
			"location": "Munich, Germany",
			"position": "Science for Refugees"
		}, {
			"startDate": "October 2016",
			"organization": "Pollinate Energy",
			"website": "https://pollinateenergy.org/our-programs/pf/",
			"summary": "",
			"location": "Lucknow, India",
			"position": "Professional Fellowship"
		}, {
			"startDate": "September 2016, 2015, 2014",
			"organization": "IBM",
			"website": "http://www.clarendon.vic.edu.au/wp-content/uploads/IBM-EXITE-Camp-2016-Information-1.pdf",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Organised IBM Research’s involvement at EXITE Camp"
		}, {
			"startDate": "January - December 2016",
			"organization": "CSIRO",
			"website": "http://www.scientistsinschools.edu.au/ICT/",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Volunteer for “ICT in schools” program"
		}, {
			"startDate": "October 2015",
			"organization": "Seconds to give",
			"website": "http://www.secondstogive.org/",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Organising a fundraiser event"
		},  {
			"startDate": "April 2015",
			"organization": "Journey to Nepal",
			"website": "http://www.journey-nepal.org/Nepal/",
			"endDate": "",
			"summary": "",
			"location": "Kathmandu, Nepal",
			"position": "Volunteer in Nepal"
		}, {
			"startDate": "May 2014, 2015",
			"organization": "Foundation of Young Australians",
			"website": "http://www.fya.org.au/",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Speed mentoring of highschool students"
		}, {
			"startDate": "November 2013",
			"organization": "Residential Indigenous Science Experience",
			"website": "http://www.gtac.edu.au/rise/",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Presenter and speed mentor"
		}, {
			"startDate": "June 2011",
			"organization": "BIO21",
			"website": "http://www.bio21.unimelb.edu.au/",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Volunteer at Open Day (Nanotechnology Stand)"
		}, {
			"startDate": "October 2011 - 2013",
			"organization": "CSIRO",
			"website": "http://www.scientistsinschools.edu.au/",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Volunteer for “Scientist in Schools Program”"
		}, {
			"startDate": "September 2011",
			"organization": "University of Melbourne",
			"website": "",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Organizer of a Careers Expo for undergraduate students"
		},{
			"startDate": "June & October 2010",
			"organization": "School of Chemistry Retreat",
			"website": "",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Postgraduate Student Representative"
		}, {
			"startDate": "August 2010",
			"organization": "University of Melbourne",
			"website": "",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Volunteer at Open Day "
		}, {
			"startDate": "August 2010",
			"organization": "Herald Sun Home and Garden Expo",
			"website": "",
			"endDate": "",
			"summary": "",
			"location": "Melbourne, Australia",
			"position": "Representative at Nanotechnology stand"
		}
	],

	"members":[ {
			"startDate": "April 2011 - April 2012",
			"organization": "Chemistry Postgraduate Society",
			"website": "http://chemistry.unimelb.edu.au/engage/chemistry-postgraduate-society-cps",
			"summary": "",
			"location": "",
			"position": "President"
		}, {
			"startDate": "May 2010",
			"organization": "Australian Nanotechnology Network",
			"website": "http://www.ausnano.net/index.php?page=home",
			"summary": "",
			"location": "",
			"position": "Member"
		}, {
			"startDate": "April 2010 - April 2011",
			"organization": "Chemistry Postgraduate Society",
			"website": "http://chemistry.unimelb.edu.au/engage/chemistry-postgraduate-society-cps",
			"summary": "",
			"location": "",
			"position": "Treasurer"
		}, {
			"startDate": "August 2009 - April 2012",
			"organization": "Chemistry Postgraduate Society",
			"website": "http://chemistry.unimelb.edu.au/engage/chemistry-postgraduate-society-cps",
			"summary": "",
			"location": "",
			"position": "Member"
		}, {
			"startDate": "since February 2007",
			"organization": "Deutsche Physikalische Gesellschaft",
			"website": "https://www.dpg-physik.de/index.html?lang=en",
			"summary": "",
			"location": "",
			"position": "Member"
		}

	],

	"skillImages": [{
				"image": "/images/resume/compskill.jpg"
			},{
				"image": "/images/resume/expskill.jpg"
			},{
				"image": "/images/resume/transfskill.jpg"
			}],

	"skills": [{
		"ComputationalSkills":
			[{
				"name": "Web Development",
				"level": "Intermediate",
				"keywords": [
					"HTML",
					"CSS",
					"Javascript",
					"GoogleAppEngine"
				]
			}, {
				"name": "Programming",
				"level": "Masters",
				"keywords": [
					"Python",
					"C++",
					"Bash"
				]
			}, {
				"name": "Machine Learning",
				"level": "Intermediate",
				"keywords": [
					"Tensorflow",
					"R"
				]
			}, {
				"name": "Computational Physics",
				"level": "Intermediate",
				"keywords": [
					"Lammps",
					"Colvars",
					"VMD",
					"OpenFoam"
				]
			}, {
				"name": "Software",
				"level": "Master",
				"keywords": [
					"Igor", "Latex", "Labview", "Open Office Programs", "Lotus Notes", "Adobe Illustrator", "GitHub"
				]
			}, {
				"name": "Operating Systems",
				"level": "Master",
				"keywords": [
					"Linux",
					"Mac",
					"Windows"
				]
			}]
	}, {
		"ExperimentalSkills": 
			[{
				"name": "Characterisation",
				"level": "Master",
				"keywords": [
					"Scanning Electron Microscopy", "Atomic Force Microscopy", " Zeta-Potential Analyzer", " I-V/FET Measurements", " Absorption/Fluorescent Spectroscopy", " Lifetime Measurements (Streak Camera, TCSPC, Pump-Probe, Flashphotolysis)", " Widefield Spectroscopy", " Confocal & Darkfield Single Particle Spectroscopy"
				]
			}, {
				"name": "Synthesis & Device Preparation",
				"level": "Master",
				"keywords": [
					"Focuesd Ion Beam Etching", " Metal and Semiconductor Nanocrystal Synthesis, Sol-gel Processing", " Thermal Evaporation", " Sputter Coater", " Photo-lithography", " Clean Room Procedures", "Glovebox/Schlenkline Procedures", "Cryostat Procedures"
				]
			}]
	}, {
		"TransferableSkills":
			["Establishing and managing collaborative research projects with an aim to build synergies", "Excellent communication skills (thesis, scientific journals and presentations)", "Hands-on experience in teaching, demonstrating to others, mentoring and public speaking", "Managing multiple tasks concurrently", "Working independently or within a team, including interdisciplinary collaborations", "Fast learning and aptitude", "Ability to develop excellent working relationships with a diverse range of people", "Passion for ongoing learning and a strong belief in always applying myself to my personal best", "Self-motivated with enthusiasm and energy to achieve career goals", "Logical, dynamic, entrepreneurial thinker with strong analytical and research skills", "Excellent problem solving skills due to a holistic and provident thinking approach", "Managing projects according to time, budget and performance", "Optimistic attitude towards life in general", "Taking initiative in creating a social atmosphere at work  ", "Vital part in the Beach volleyball community thanks to social and team skills"
			]
	}],

	"languages": [{
		"name": "German",
		"level": "native speaker"
	}, {
		"name": "English",
		"level": "fluent"
	}, {
		"name": "Spanish",
		"level": "basic"
	}],

	"interests": [
			"Rock climbing",
			"Mountaineering",
			"Beachvolleyball",
			"Gardening",
			"Yoga",
			"Traveling",
			"Sustainable Living",
			"Reading"
		],

	"references": [{
		"name": "Prof. Paul Mulvaney",
		"position": "Group Leader - Nanoparticle Group University of Melbourne",
		"email": "mulvaney@unimelb.edu.au",
		"reference": ""
	}]
}
