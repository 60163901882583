import React from 'react';
import { withRouter } from "react-router";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './Home.css'
import '../App.css'

library.add(fas)

class Impressum extends React.Component {

  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    return (
      <div>   
        <div className="topSection">
        </div>
        <div className="infoBox infoBoxWhite"> 
          <h1>Impressum</h1>
        <div>
Julia Baldauf<br/>
M&#252;nchnerstrasse 25<br/>
83624 Otterfing<br/>
<div>&nbsp;</div>
Tel: 0163 1378833<br/>
<a href="mailto:mail@julia-baldauf.de">mail@julia-baldauf.de</a>
</div>
<div><strong>Umsatzsteuer-ID:</strong><br/>
Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:<br/>
DE340305549&#160;</div>
<meta name="generator" content="Impressum-Generator der Kanzlei Hensche Rechtsanwälte"/>
<div>&nbsp;</div>

<div>&nbsp;</div>
<div><strong>Disclaimer (Haftungsausschluss)</strong></div>
<div><strong>1. Haftung f&#252;r Inhalte</strong></div>
<div>Als Diensteanbieter sind wir gem&#228;&#223; &#167; 7 Abs. 1 TMG f&#252;r eigene Inhalte auf diesen Seiten nach den 
allgemeinen Gesetzen verantwortlich. Nach &#167;&#167; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht 
verpflichtet, &#252;bermittelte oder gespeicherte fremde Informationen zu &#252;berwachen oder nach Umst&#228;nden zu 
forschen, die auf eine rechtswidrige T&#228;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung 
von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&#252;hrt. Eine diesbez&#252;gliche Haftung ist 
jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&#246;glich. Bei Bekanntwerden von 
entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</div>
<div>&nbsp;</div>
<div><strong>2. Haftung f&#252;r Links</strong></div>
<div>Diese Website enth&#228;lt Links zu externen Webseiten Dritter, auf deren Inhalte kein Einfluss genommen werden kann. 
Deshalb kann f&#252;r diese fremden Inhalte auch keine Gew&#228;hr &#252;bernommen werden. F&#252;r die Inhalte der 
verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden 
zum Zeitpunkt der Verlinkung auf m&#246;gliche Rechtsverst&#246;&#223;e &#252;berpr&#252;ft. Rechtswidrige Inhalte waren 
zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne 
konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige 
Links umgehend von dieser Website auf die rechtsverletzende Site entfernen.</div>
<div>&nbsp;</div>
<div><strong>3. Urheberrecht</strong></div>
<div>Die durch die Diensteanbieter, deren Mitarbeiter und beauftragte Dritte erstellten Inhalte und Werke auf diesen Seiten 
unterliegen dem deutschen Urheberrecht. Die Vervielf&#228;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung 
au&#223;erhalb der Grenzen des Urheberrechtes bed&#252;rfen der vorherigen schriftlichen Zustimmung des jeweiligen Autors 
bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&#252;r den privaten, nicht kommerziellen Gebrauch 
gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter 
beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine 
Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von 
Rechtsverletzungen werden derartige Inhalte umgehend entfernen.</div>
<div>&nbsp;</div>
<div>Dieses Impressum wurde mit Hilfe des <a target="_blank" rel="noreferrer"
href="http://www.hensche.de/impressum-generator.html">Impressum-Generators</a> von <a target="_blank" rel="noreferrer"
href="http://www.hensche.de/Rechtsanwalt_Arbeitsrecht_Muenchen.html">HENSCHE Rechtsanw&auml;lte, M&uuml;nchen 
(Maxvorstadt)</a> erstellt.</div>
          </div>
      </div>
    )
  }
}

export default withRouter(Impressum);
