export const home = {
    'EN': 'Julia Baldauf',
    'DE': 'Julia Baldauf'
}
export const work = {
    'EN': 'Work',
    'DE': 'Arbeit'
}
export const teaching = {
    'EN': 'Teaching',
    'DE': 'Lehre'
}
export const about = {
    'EN': 'About me',
    'DE': 'Über mich'
}

export const home_heading = {
    'EN': 'PO - Fullstack Data Scientist - Adventurer',
    'DE': 'PO - Fullstack Data Scientist - Adventurer'
}
export const about_heading = {
    'EN': 'ABOUT ME',
    'DE': 'ÜBER MICH'
}
export const teaching_heading = {
    'EN': 'Teaching - inspiring others',
    'DE': 'Lehre - andere inspirieren'
}
export const work_heading = {
    'EN': 'PAST PROJECTS',
    'DE': 'BISHERIGE PROJEKTE'
}


export const home_pitch = {
    'EN': 'I am naturally a very enthusiastic and energetic person and I like to utilise my passion for data science, innovation and the outdoors. As a project leader in the 21st century, as well as an adventurer, agility really is a quality that I live and breathe. As the rate of change in the world continuous to accelerate, agile and flexible ways of working are becoming essential to successful project management practices. Equally in the mountains and the office I am constantly required to make quick decisions on the run. This ability to make smart and informed decisions in a dynamic and changing environment is key to maintaining focus, driving successful project outcome and allowing space for creative and innovative solutions aiming to work towards the Sustainable Development Goals. For me successful collaboration starts with respect, open communication and trust for each other and I aim to bring these key values into each project. If you are initiating or delivering product development, digital change or innovation projects, I would be pleased to support you in this journey as a product owner, project manager or full stack data scientist.',
    'DE': 'Ich bin von Natur aus ein sehr enthusiastischer und energiegeladener Mensch und nutze gerne meine Leidenschaft für Data Science, Innovation und die Natur. Als Projektleiter im 21. Jahrhundert und als Abenteurer ist Agilität eine Eigenschaft, die ich lebe und atme. Da sich der Wandel in der Welt immer schneller vollzieht, sind agile und flexible Arbeitsweisen für ein erfolgreiches Projektmanagement unerlässlich. Sowohl in den Bergen als auch im Büro bin ich ständig gefordert, schnelle Entscheidungen zu treffen. Diese Fähigkeit, in einem dynamischen und sich verändernden Umfeld kluge und fundierte Entscheidungen zu treffen, ist der Schlüssel, um den Fokus aufrechtzuerhalten, erfolgreiche Projektergebnisse voranzutreiben und Raum für kreative und innovative Lösungen zu schaffen, die auf die Ziele der nachhaltigen Entwicklung abzielen. Für mich beginnt eine erfolgreiche Zusammenarbeit mit gegenseitigem Respekt, offener Kommunikation und Vertrauen, und ich bin bestrebt, diese Schlüsselwerte in jedes Projekt einzubringen. Wenn Sie Produktentwicklungs-, digitale Veränderungs- oder Innovationsprojekte initiieren oder durchführen, würde ich mich freuen, Sie auf diesem Weg als Product Owner, Projektmanager oder Full Stack Data Scientist zu unterstützen.'
}
export const work_pitch = {
    'EN': 'Selected projects I have been involved in:',
    'DE': 'Ausgewählte Projekte an denen ich mitgewirkt habe:'
}
export const teaching_pitch = {
    'EN': 'I have always enjoyed sharing my knowledge with others, especially with children. I want them to see that the typical scientist is not male with a white beard :)',
    'DE': 'Ich habe es immer genossen, mein Wissen mit anderen zu teilen, besonders mit Kindern. Sie sollen sehen, dass die typische WissenschaftlerIn nicht männlich mit einem weissen Bart ist :)'
}
export const about_pitch = {
    'EN': '',
    'DE': ''
}


export const project_1_heading = {
    'EN': 'OCT Image Labelling',
    'DE': 'OCT Bilderkennung'
}
export const project_2_heading = {
    'EN': 'Tycho.Cloud',
    'DE': 'Tycho.Cloud'
}
export const project_3_heading = {
    'EN': 'Remote Social Butterfly',
    'DE': 'Remote Social Butterfly'
}
export const project_4_heading = {
    'EN': 'Application Software Modernization',
    'DE': 'Anwendungs Software Modernisierung'
}

export const project_1_text = {
    'EN': 'From optical coherence tomography (OCT) data of arteries doctors can decide if a stent is placed correctly or need replacement. This involves a time consuming analysis of 200 OCT images by the doctor. My team at the IBM Research Lab Australia in Melbourne was driving a project to automate this process and support the doctor in the decision making process. The automated analysis process involved building a 3D model from the 2D OCT images and then running computational fluid dynamic simulations on this model to calculate the blood flow through the artery, giving the doctor valuable insight.',
    'DE': 'Aus den Daten der optischen Kohärenztomographie (OCT) von Arterien können Ärzte entscheiden, ob ein Stent richtig platziert ist oder ausgetauscht werden muss. Dies erfordert eine zeitaufwändige Analyse von 200 OCT Bildern durch den Arzt. Mein Team am IBM Research Lab Australia in Melbourne hat ein Projekt vorangetrieben, um diesen Prozess zu automatisieren und den Arzt bei der Entscheidungsfindung zu unterstützen. Der automatisierte Analyseprozess umfasste die Erstellung eines 3D-Modells aus den 2D-OCT-Bildern und die anschließende Durchführung von computergestützten fluiddynamischen Simulationen an diesem Modell, um den Blutfluss durch die Arterie zu berechnen, was dem Arzt wertvolle Erkenntnisse liefert.'
}
export const project_2_text = {
    'EN': 'During my time at Nanotemeper Technolgies one focus was on Datascience and its possibility for data-driven data analysis. For this purpose a data pipeline was debeloped to collect data from customers and within Nanotemper. One part of the data pipeline was to collect data from customers by offering a cloud based analysis software.',
    'DE': 'Während meiner Zeit bei Nanotemeper Technolgies lag ein Schwerpunkt auf Datascience und der Möglichkeit zur datengetriebenen Datenanalyse. Zu diesem Zweck wurde eine Datenpipeline entwickelt, um Daten von Kunden und innerhalb von Nanoteper zu sammeln. Ein Teil der Datenpipeline war es, Daten von Kunden zu sammeln, indem eine cloudbasierte Analysesoftware angeboten wurde.'
}
export const project_3_text = {
    'EN': 'When the pandemic started an everyone was working for home I missed the water cooling conversations and interactions with people before and after meetings. Lacking this communication I realised once again that communication is crucial to a healthy culture and innovation.',
    'DE': 'Als die Pandemie begann und alle zu Hause arbeiteten, vermisste ich die kühlenden Gespräche und Interaktionen mit den Leuten vor und nach den Meetings. In Ermangelung dieser Kommunikation wurde mir wieder einmal klar, dass Kommunikation für eine gesunde Kultur und Innovation entscheidend ist.'
}
export const project_4_text = {
    'EN': 'Many companies still use and sell software that was initiated 20-30 years ago and has evolved over the years, mainly adding new functionalities. However, a lot has changed in that time and a lot of technical debt has usually built up, making it difficult to maintain the software, let alone add new functionality with the demands of today. Thus, the question of how to modernize this software to remain competitive in the future inevitably arises.',
    'DE': 'Viele Unternehmen nutzen und verkaufen immer noch Software, die vor 20-30 Jahren initiiert wurde und im Laufe der Jahre weiterentwickelt wurde, wobei hauptsächlich neue Funktionalitäten hinzugefügt wurden. Doch in dieser Zeit hat sich viel verändert und es haben sich meistens viele technische Schulden aufgebaut, die es schwer machen, die Software zu pflegen, geschweige denn neue Funktionalitäten mit den Ansprüchen von heute hinzuzufügen. Somit stellt sich unausweichlich die Frage wie man diese Software modernisiert um auch un Zukunft wettbewerbsfähig zu bleiben.'
}

export const project_1_contribution = {
    'EN': 'My contribution was to convert the 2D OCT images into a 3D model, which required first classifying the different areas of the images. I used a semantic image labeling technique that trains a convolutional neural network (CNN) to identify each pixel individually depending on the neighbouring pixels. For this purpose, I used the Python Tensorflow package and successfully labeled the pixels with 85% accuracy.',
    'DE': 'Mein Beitrag bestand darin, die 2D-OCT-Bilder in ein 3D-Modell umzuwandeln, wofür die verschiedenen Bereiche der Bilder erstmal klassifiziert werden mussten. Ich verwendete eine semantische Bildbeschriftungstechnik, die ein convolutional neural  Netzwerk (CNN) trainiert, um jedes Pixel einzeln in Abhängigkeit der Nachbarpixel zu identifizieren. Zu diesem Zweck habe ich das Tensorflow-Paket von Python verwendet und die Pixel erfolgreich mit einer Genauigkeit von 85 % beschriftet.'
}
export const project_2_contribution= {
    'EN': 'I was driving this cloud based analysis software as a Product Owner and also supported the front-end development. The Tycho.Cloud software allows customers to connect their device to the software so that data is automatically uploaded which can then be analysed with new analysis tools only offered in the cloud software.',
    'DE': 'Ich habe diese cloudbasierte Analysesoftware als Product Owner vorangetrieben und auch die Front-End-Entwicklung unterstützt. Die Tycho.Cloud-Software ermöglicht es Kunden, ihr Gerät mit der Software zu verbinden, so dass Daten automatisch hochgeladen werden, die dann mit neuen Analyse-Tools, die nur in der Cloud-Software angeboten werden, analysiert werden können.'
}
export const project_3_contribution = {
    'EN': 'To foster communication in remote times and also between people who normally do not get to talk during work hours I started programming Remote Social Butterfly. It is a data-driven communication organiser pairing chat partners that make sense (i.e. not random) and lowers the barriers for people to get in touch by automating contact initiation. I planned and built the webpage by myself using AWS Serverless Architecture, python and java for the backend logic and JavaScript with the React framework for the front end.',
    'DE': 'Um die Kommunikation in entlegenen Zeiten und auch zwischen Menschen zu fördern, die normalerweise während der Arbeitszeit nicht zum Reden kommen, begann ich mit der Programmierung von Remote Social Butterfly. Es ist ein datengesteuerter Kommunikationsorganisator, der Chatpartner zusammenbringt, die Sinn machen (d.h. nicht zufällig) und die Barrieren für die Kontaktaufnahme durch Automatisierung der Kontaktaufnahme senkt. Ich habe die Webseite selbst geplant und gebaut und dabei AWS Serverless Architecture, Python und Java für die Backend-Logik und JavaScript mit dem React-Framework für das Frontend verwendet.'
}
export const project_4_contribution= {
    'EN': 'As project manager for the modernization of this software, I supported my client in making an informed decision by evaluating different paths . This meant working closely with the stakeholders to define the goals for the modern software at the outset and jointly weighing different implementation options based on these. ',
    'DE': 'Als Projektleitung für die Modernisierung dieser Software habe ich meinen Auftraggeber unterstütz eine fundierte Entscheidung zu treffen, indem ich verschiedene Wege evaluiert habe. Dies bedeutete eine enge Zusammenarbeit mit den Stakeholdern um zu Beginn die Ziele für die modernen Software zu definieren und anhand dieser gemeinsam verschiedene Umsetzungsmöglichkeiten abzuwiegen. '
}

export const project_project = {
    'EN': 'Project',
    'DE': 'Projekt'
}

export const project_contribution = {
    'EN': 'Contribution',
    'DE': 'Beitrag'
}

export const panel_work_heading = {
    'EN': 'W O R K',
    'DE': 'A R B E I T' 
}

export const panel_at = {
    'EN': 'at',
    'DE': ',' 
}

export const panel_in = {
    'EN': 'in',
    'DE': 'in' 
}

export const panel_as = {
    'EN': 'as',
    'DE': 'als' 
}

export const panel_edu_heading = {
    'EN': 'E D U C A T I O N',
    'DE': 'A U S B I L D U N G' 
}

export const panel_pub_heading = {
    'EN': 'P U B L I C A T I O N S',
    'DE': 'V E R Ö F F E N T L I C H U N G E N' 
}

export const panel_patent_heading = {
    'EN': 'P A T E N T S',
    'DE': 'P A T E N T E' 
}

export const panel_furtheredu_heading = {
    'EN': 'F U R T H E R  -  E D U C A T I O N',
    'DE': 'W E I T E R B I L D U N G' 
}

export const panel_awards_heading = {
    'EN': 'A W A R D S',
    'DE': 'A U S Z E I C H N U N G E N' 
}

export const panel_volunteer_heading = {
    'EN': 'V O L U N T E E R W O R K',
    'DE': 'F R E I W I L L I G E N A R B E I T' 
}

export const impressum = {
    'EN': 'Imprint',
    'DE': 'Impressum' 
}
export const datenschutz = {
    'EN': 'Data Privacy',
    'DE': 'Datenschutz' 
}
    
export const impressum_text = {
    'EN': 'Imprint',
    'DE': 'Impressum' 
}
export const datenschutz_text = {
    'EN': 'Data Privacy',
    'DE': 'Datenschutz' 
}
    