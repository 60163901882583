import React from 'react';
import { withRouter } from "react-router";
import { work_heading, project_1_heading, project_2_heading, project_3_heading, project_4_heading, project_project, project_contribution, project_1_text, project_2_text, project_3_text, project_4_text, project_1_contribution,project_2_contribution,project_3_contribution,project_4_contribution} from './Variables.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './Home.css'
import '../App.css'

library.add(fas)

class Work extends React.Component {

  componentDidMount () {
    window.scrollTo(0, 0);
  }

  render () {
    return (
      <div>   
        <div className="topSection">
          <div className="image-container">
            <img className="image-image" alt='' src="images/chiemsee.jpg" />
            <div className="image-text">
              <div className="heading1">{work_heading[this.props.language]}</div>
            </div>
          </div>     
        </div>
        <div className="infoBox infoBoxWhite"> 
          <div className="container planContainer">
            <div className="usecase-orga-grid">
              <input type="checkbox" class="checkbox-invisible" id="uc1-checkbox"/>
              <div className="usecaseTitle plan one">   
                  <label className="whoHeading uc1H" for="uc1-checkbox"> {project_1_heading[this.props.language]} </label>
              </div>
              <input type="checkbox" class="checkbox-invisible" id="uc2-checkbox"/>
              <div className="usecaseTitle plan five">
                  <label className="whoHeading uc2H" for="uc2-checkbox">{project_2_heading[this.props.language]}</label>
              </div>

              <div className="usecaseText plan two uc1">
                  <div>
                      <div className="heading3" style={{display: 'inline'}}>{project_project[this.props.language]}</div>
                  </div>
                  <div className="projectText"> 
                    {project_1_text[this.props.language]}
                  </div>
              </div>
              <div className="usecaseText plan six uc2">
                  <div>
                      <div className="heading3" style={{display: 'inline'}}>{project_project[this.props.language]}</div>
                  </div>
                  <div className="projectText"> 
                   {project_2_text[this.props.language]}
                  </div>
              </div>

              <div className="usecaseText plan three uc1">
                  <div>
                      <div className="heading3"  style={{display: 'inline'}}>  {project_contribution[this.props.language]}</div>
                  </div>
                  <div className="projectText">
                    {project_1_contribution[this.props.language]}
                    <FontAwesomeIcon icon='github'/> 
                  </div>
              </div>
              <div className="usecaseText plan seven uc2">
                  <div>
                      <div className="heading3" style={{display: 'inline'}}>{project_contribution[this.props.language]}</div>
                  </div>
                  <div className="projectText">
                    {project_2_contribution[this.props.language]}
                  </div>
              </div>
              
              <div className="usecaseBottom plan four uc1">
                <div className=""> <img alt="appIcon" className= "about-image" src="images/OCT-image.png" /></div>
              </div>
              <div className="usecaseBottom plan eight uc2">
              <div className=""> <img alt="appIcon" className= "about-image" src="images/TychoCloud-2.png" /></div>
              </div>
              
              <input type="checkbox" class="checkbox-invisible" id="uc3-checkbox"/>
              <div className="usecaseTitle plan nine">
                  <label className="whoHeading uc3H" for="uc3-checkbox">{project_3_heading[this.props.language]}</label>
              </div>
              <input type="checkbox" class="checkbox-invisible" id="uc4-checkbox"/>
              <div className="usecaseTitle plan thirteen">
                  <label className="whoHeading uc4H" for="uc4-checkbox">{project_4_heading[this.props.language]}</label>
              </div>

              <div className="usecaseText plan ten uc3">
                  <div>
                      <div className="heading3"  style={{display: 'inline'}}>{project_project[this.props.language]}</div>
                  </div>
                  <div className="projectText"> 
                    {project_3_text[this.props.language]}
                  </div>
              </div>
              <div className="usecaseText plan fourteen uc4">
                  <div>
                      <div className="heading3"  style={{display: 'inline'}}>{project_project[this.props.language]}</div>
                  </div>
                  <div className="projectText"> 
                    {project_4_text[this.props.language]}    
                  </div>
              </div>

              <div className="usecaseText plan eleven uc3">
                  <div>
                      <div className="heading3"  style={{display: 'inline'}}>{project_contribution[this.props.language]}</div>
                  </div>
                  <div className="projectText">
                    {project_3_contribution[this.props.language]}
                  </div>
              </div>
              <div className="usecaseText plan fivteen uc4">
                  <div>
                      <div className="heading3"  style={{display: 'inline'}}>{project_contribution[this.props.language]}</div>
                  </div>
                  <div className="projectText">
                    {project_4_contribution[this.props.language]}
                  </div>
              </div>

              <div className="usecaseBottom plan twelve uc3">
                <div className=""> <img alt="appIcon" className= "about-image" src="images/Resobu.png" /></div>
              </div>
              <div className="usecaseBottom plan sixteen-2 uc4">
                <div className=""> <img alt="appIcon" className= "about-image" src="images/foggy_forest.jpg" /></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Work);

/*
  <div className="infoBox infoBoxWhite contentContainer"> 
    <WhyForWhoEvent 
      onGotoSignUp = {this.gotoSignUp}
    />         
  </div>
        <div className="infoBox contentContainer"> 
    <HowEvent />
  </div>

*/
